export function capitalizeFirstLetter(s: string) {
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getCookie(name: string): string {
  if (!document.cookie) {
    return '';
  }
  const token = document.cookie
    .split(';')
    .map((c) => c.trim())
    .filter((c) => c.startsWith(`${name}=`));

  if (token.length === 0) {
    return '';
  }
  return decodeURIComponent(token[0].split('=')[1]);
}

export function capitalizeEachWord(
  s: string,
  { isName = false }: { isName?: boolean } = {}
): string {
  if (!s) {
    return '';
  }

  // Don't normalize (force-lowercase) when we're dealing with names.
  const normalizerFunc = isName
    ? (s: string) => s
    : (chunk: string) => chunk.trim().toLowerCase();

  return s
    .split(' ')
    .map(normalizerFunc)
    .filter((chunk) => !!chunk)
    .map((chunk) => capitalizeFirstLetter(chunk))
    .join(' ');
}
