import {
  AgeRange,
  FilterCondition,
  FilterGroup,
  LogicalOperator,
  Operator,
  PGSequencingTestResult,
  RootFilterGroup,
} from './Table.types';
import { SelectProps } from '../../../patients/atoms';

export const ALL_OR_ANY_ITEMS: SelectProps['items'] = [
  {
    key: LogicalOperator.And,
    label: 'all',
    value: LogicalOperator.And,
  },
  {
    key: LogicalOperator.Or,
    label: 'any',
    value: LogicalOperator.Or,
  },
];

export const LIST_OPERATOR_ITEMS: SelectProps['items'] = [
  {
    key: Operator.Contains,
    label: `${Operator.Contains} contains`,
    value: Operator.Contains,
  },
];

export const OPERATOR_ITEMS: SelectProps['items'] = [
  {
    key: Operator.Equals,
    label: `${Operator.Equals} equals`,
    value: Operator.Equals,
  },
  {
    key: Operator.NotEquals,
    label: `${Operator.NotEquals} not equals`,
    value: Operator.NotEquals,
  },
  {
    key: Operator.Contains,
    label: `${Operator.Contains} contains`,
    value: Operator.Contains,
  },
  {
    key: Operator.NotContains,
    label: `${Operator.NotContains} does not contain`,
    value: Operator.NotContains,
  },
  {
    key: Operator.IsSet,
    label: `${Operator.IsSet} is set`,
    value: Operator.IsSet,
  },
  {
    key: Operator.NotSet,
    label: `${Operator.NotSet} is not set`,
    value: Operator.NotSet,
  },
  {
    key: Operator.LessThan,
    label: `${Operator.LessThan} less than`,
    value: Operator.LessThan,
  },
  {
    key: Operator.GreaterThan,
    label: `${Operator.GreaterThan} greater than`,
    value: Operator.GreaterThan,
  },
  {
    key: Operator.LessThanOrEqual,
    label: `${Operator.LessThanOrEqual} less than or equal`,
    value: Operator.LessThanOrEqual,
  },
  {
    key: Operator.GreaterThanOrEqual,
    label: `${Operator.GreaterThanOrEqual} greater than or equal`,
    value: Operator.GreaterThanOrEqual,
  },
];

export const AGE_RANGE_ITEMS: SelectProps['items'] = [
  {
    key: AgeRange.Unknown,
    label: 'Unknown',
    value: AgeRange.Unknown,
  },
  {
    key: AgeRange.Range_0_5,
    label: AgeRange.Range_0_5,
    value: AgeRange.Range_0_5,
  },
  {
    key: AgeRange.Range_5_10,
    label: AgeRange.Range_5_10,
    value: AgeRange.Range_5_10,
  },
  {
    key: AgeRange.Range_10_15,
    label: AgeRange.Range_10_15,
    value: AgeRange.Range_10_15,
  },
  {
    key: AgeRange.Range_15_20,
    label: AgeRange.Range_15_20,
    value: AgeRange.Range_15_20,
  },
  {
    key: AgeRange.Range_20_25,
    label: AgeRange.Range_20_25,
    value: AgeRange.Range_20_25,
  },
  {
    key: AgeRange.Range_25_30,
    label: AgeRange.Range_25_30,
    value: AgeRange.Range_25_30,
  },
  {
    key: AgeRange.Range_30_35,
    label: AgeRange.Range_30_35,
    value: AgeRange.Range_30_35,
  },
  {
    key: AgeRange.Range_35_40,
    label: AgeRange.Range_35_40,
    value: AgeRange.Range_35_40,
  },
  {
    key: AgeRange.Range_40_45,
    label: AgeRange.Range_40_45,
    value: AgeRange.Range_40_45,
  },
  {
    key: AgeRange.Range_45_50,
    label: AgeRange.Range_45_50,
    value: AgeRange.Range_45_50,
  },
  {
    key: AgeRange.Range_50_55,
    label: AgeRange.Range_50_55,
    value: AgeRange.Range_50_55,
  },
  {
    key: AgeRange.Range_55_60,
    label: AgeRange.Range_55_60,
    value: AgeRange.Range_55_60,
  },
  {
    key: AgeRange.Range_60_65,
    label: AgeRange.Range_60_65,
    value: AgeRange.Range_60_65,
  },
  {
    key: AgeRange.Range_65_70,
    label: AgeRange.Range_65_70,
    value: AgeRange.Range_65_70,
  },
  {
    key: AgeRange.Range_70_75,
    label: AgeRange.Range_70_75,
    value: AgeRange.Range_70_75,
  },
  {
    key: AgeRange.Range_75_80,
    label: AgeRange.Range_75_80,
    value: AgeRange.Range_75_80,
  },
  {
    key: AgeRange.Range_80_85,
    label: AgeRange.Range_80_85,
    value: AgeRange.Range_80_85,
  },
  {
    key: AgeRange.Range_85_90,
    label: AgeRange.Range_85_90,
    value: AgeRange.Range_85_90,
  },
  {
    key: AgeRange.Range_90_Plus,
    label: AgeRange.Range_90_Plus,
    value: AgeRange.Range_90_Plus,
  },
];

export const PG_SEQUENCING_TEST_RESULT_ITEMS: SelectProps['items'] = [
  {
    key: PGSequencingTestResult.Unknown,
    label: 'Unknown',
    value: PGSequencingTestResult.Unknown,
  },
  {
    key: PGSequencingTestResult.Negative,
    label: 'Negative',
    value: PGSequencingTestResult.Negative,
  },
  {
    key: PGSequencingTestResult.Positive,
    label: 'Positive',
    value: PGSequencingTestResult.Positive,
  },
  {
    key: PGSequencingTestResult.Carrier,
    label: 'Carrier',
    value: PGSequencingTestResult.Carrier,
  },
  {
    key: PGSequencingTestResult.Unclassified,
    label: 'Unclassified',
    value: PGSequencingTestResult.Unclassified,
  },
  {
    key: PGSequencingTestResult.PotentiallyPositive,
    label: 'Potentially Positive',
    value: PGSequencingTestResult.PotentiallyPositive,
  },
  {
    key: PGSequencingTestResult.Uncertain,
    label: 'Uncertain',
    value: PGSequencingTestResult.Uncertain,
  },
];

export function createNewRootFilters(): RootFilterGroup {
  return {
    operator: LogicalOperator.And,
    filters: [createNewFilterGroup()],
  };
}

export function createNewFilterGroup(): FilterGroup {
  return {
    operator: LogicalOperator.Or,
    filters: [],
  };
}

export function createNewFilterCondition(
  columnId: string,
  operator?: Operator
): FilterCondition {
  return {
    columnId,
    operator: operator || Operator.Equals,
    value: null as unknown as string,
  };
}
