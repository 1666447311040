import { initializeRudderStack } from '@/helpers/analytics/rudderstack';
import { initSentry } from '@/helpers/sentry';

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

function initAnalytics() {
  // Rudderstack
  initializeRudderStack();

  // Sentry
  if (SENTRY_DSN) {
    initSentry();
  }
}

export function initApp() {
  initAnalytics();
}
