import { Link, Button } from '@pg-web/bearded-dragon-ui';
import { paths } from '@pg-web/bearded-dragon-ui';

export const ErrorFallback = () => {
  return (
    <div className="w-full h-screen flex justify-center items-center p-8">
      <div className="max-w-3xl w-full flex flex-col gap-2 text-center justify-center items-center">
        <p className="font-bold text-xl text-peony">
          Oops! Something went wrong
        </p>
        <p className="text-lg font-sans">
          We are sorry, but we encountered an error. You can try refreshing the
          page below.
        </p>
        <Button
          className="mt-4"
          onClick={() => window.location.assign(window.location.origin)}
        >
          Refresh
        </Button>
        <Link href={paths.home.getHref()} size="lg">
          Go to homepage
        </Link>
      </div>
    </div>
  );
};
