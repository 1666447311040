import {
  FormResponseResult,
  FormResponseStatus,
  OrderDisplayBaseStatus,
  OrderDisplayStatus,
  OrderStatus,
  OrderTimelineDisplayStatus,
  OrderVisitStatus,
  OrderVisitTimelineDisplayStatus,
  PageValidation,
  ResolveStatusStateProps,
  StatusSidebarStatus,
  StatusSidebarStatusType,
  StatusSidebarStatusTypeInfoNeededMixin,
} from './StatusPage.types';
import { paths } from '../../../paths';
import { StatusCardProps } from './StatusCard';
import dayjs from 'dayjs';
import { SelectProps } from '../../atoms';

export function isOrderTimelineStatus(
  value: string
): value is OrderTimelineDisplayStatus {
  return Object.values(OrderTimelineDisplayStatus).includes(
    value as OrderTimelineDisplayStatus
  );
}

export function isOrderVisitTimelineStatus(
  value: string
): value is OrderVisitTimelineDisplayStatus {
  return Object.values(OrderVisitTimelineDisplayStatus).includes(
    value as OrderVisitTimelineDisplayStatus
  );
}

function isOrderVisitStatus(status: string): status is OrderVisitStatus {
  return Object.values(OrderVisitStatus).includes(status as OrderVisitStatus);
}

export function resolveState({
  status,
  result,
  order,
  submittedTimestamp,
  acceptedInformedConsent,
  pageValidation,
  referenceId,
}: StatusCardProps): OrderDisplayStatus {
  // DO NOT CHANGE THE ORDER OF THESE CHECKS (unless you know what you're doing)

  // PreApproved states
  if (
    (status === FormResponseStatus.Approved ||
      status === FormResponseStatus.PreEligible) &&
    !acceptedInformedConsent
  ) {
    if (!pageValidation?.isValid || !referenceId) {
      // Approved but info needed
      return OrderDisplayBaseStatus.PreApprovedInfoNeeded;
    } else {
      // Approved but need to resubmit
      return OrderDisplayBaseStatus.ReadyToSubmit;
    }
  }

  if (!pageValidation?.isValid || !referenceId) {
    return OrderDisplayBaseStatus.InfoNeeded;
  }

  if (!acceptedInformedConsent) {
    return OrderDisplayBaseStatus.ReadyToSubmit;
  }

  // Everything below refers to states after submission
  if (
    result === FormResponseResult.Waitlist &&
    status === FormResponseStatus.Rejected &&
    submittedTimestamp &&
    dayjs().diff(dayjs.unix(submittedTimestamp), 'd') < 14
  ) {
    return OrderDisplayBaseStatus.InReview;
  }
  if (
    (result === FormResponseResult.Waitlist &&
      status === FormResponseStatus.Rejected) ||
    status === FormResponseStatus.PermanentlyRejected ||
    status === FormResponseStatus.Fraudulent
  ) {
    return OrderDisplayBaseStatus.Rejected;
  }
  if (result === FormResponseResult.TrainingResponse) {
    return OrderDisplayBaseStatus.TrainingResponse;
  }
  if (result === FormResponseResult.NotInUnitedStates) {
    return OrderDisplayBaseStatus.NotInUnitedStates;
  }
  if (status === FormResponseStatus.OptedOut) {
    return OrderDisplayBaseStatus.OptedOut;
  }
  if (
    status === FormResponseStatus.InReview ||
    status === FormResponseStatus.Pending ||
    !order
  ) {
    return OrderDisplayBaseStatus.InReview;
  }

  // This is for Patient Visit Statuses
  if (order?.status && isOrderVisitStatus(order.status)) {
    const visitStatus: OrderVisitStatus = order.status as OrderVisitStatus;
    // Order status will never overlap with genetic tests as backend will ensure that
    if (visitStatus === OrderVisitStatus.Pending) {
      return OrderVisitTimelineDisplayStatus.VisitPending;
    }
    if (visitStatus === OrderVisitStatus.LabResultsPending) {
      return OrderVisitTimelineDisplayStatus.VisitLabResultsPending;
    }
    if (visitStatus == OrderVisitStatus.LabResultsComplete) {
      return OrderVisitTimelineDisplayStatus.VisitLabResultsComplete;
    }
    return OrderDisplayBaseStatus.Approved;
  }

  return (
    {
      [OrderStatus.PendingPreTestConsult]:
        OrderDisplayBaseStatus.PendingPreTestConsult,
      [OrderStatus.PendingApproval]: OrderDisplayBaseStatus.PendingApproval,
      // Everything below will show the order timeline
      [OrderStatus.PWNApproved]: OrderTimelineDisplayStatus.KitPreparing,
      [OrderStatus.NeedsKit]: OrderTimelineDisplayStatus.KitPreparing,
      [OrderStatus.ManualLaboratoryRequest]:
        OrderTimelineDisplayStatus.KitPreparing,
      [OrderStatus.KitMailed]: OrderTimelineDisplayStatus.KitMailed,
      [OrderStatus.SamplesReceived]: OrderTimelineDisplayStatus.SamplesReceived,
      [OrderStatus.SampleQc]: OrderTimelineDisplayStatus.SamplesReceived,
      [OrderStatus.ExomeQc]: OrderTimelineDisplayStatus.SamplesReceived,
      [OrderStatus.SequencingComplete]:
        OrderTimelineDisplayStatus.SequencingComplete,
      [OrderStatus.AnalysisComplete]:
        OrderTimelineDisplayStatus.AnalysisComplete,
      [OrderStatus.PendingConsultScheduling]:
        OrderTimelineDisplayStatus.ReportReady,
      [OrderStatus.ConsultScheduled]: OrderTimelineDisplayStatus.ReportReady,
      [OrderStatus.ReportReady]: OrderTimelineDisplayStatus.ReportReady,
    }?.[order.status as OrderStatus] ?? OrderDisplayBaseStatus.Approved
  );
}

export function getFirstIncompleteSectionPathFromPageValidation(
  pageValidation?: PageValidation
): string {
  const firstIncompleteSectionKey: keyof typeof paths.dashboard = (Object.keys(
    pageValidation?.errors ?? {}
  )?.[0] ?? 'status') as keyof typeof paths.dashboard;
  return paths.dashboard[firstIncompleteSectionKey].getHref();
}

export function getHumanReadableMissingInformation(
  pageValidation?: PageValidation
): Record<string, string> {
  return Object.fromEntries(
    Object.entries({
      accountFirstName:
        pageValidation?.errors?.['account']?.firstName && 'Your first name',
      accountLastName:
        pageValidation?.errors?.['account']?.lastName && 'Your last name',
      accountPhone:
        pageValidation?.errors?.['account']?.phone && 'Phone number',
      accountEmail:
        pageValidation?.errors?.['account']?.email && 'Email address',
      accountRelationshipToPatient:
        pageValidation?.errors?.['account']?.relationshipToPatient &&
        'Relationship to patient',
      patientFirstName:
        pageValidation?.errors?.['patient']?.firstName &&
        "Patient's first name",
      patientLastName:
        pageValidation?.errors?.['patient']?.lastName && "Patient's last name",
      patientDateOfBirth:
        pageValidation?.errors?.['patient']?.dateOfBirth &&
        "Patient's date of birth",
      patientBiologicalSex:
        pageValidation?.errors?.['patient']?.biologicalSex &&
        "Patient's biological sex",
      patientPhysicians:
        pageValidation?.errors?.['patient']?.physicians &&
        "Patient's primary and treating physicians",
      diagnoses:
        pageValidation?.errors?.['diagnoses']?.diagnoses &&
        'Previous diagnoses',
      geneticTests:
        pageValidation?.errors?.['geneticTests']?.tests &&
        'Familial genetic tests and results',
      labTests:
        pageValidation?.errors?.['labTests']?.tests && 'Lab tests and results',
      assessment:
        pageValidation?.errors?.['assessment']?.conversationEnded &&
        'Symptom assessment',
    }).filter(([, value]) => !!value)
  );
}

export function resolveStatusState(
  props: ResolveStatusStateProps
): StatusSidebarStatus {
  const { isLoading, pageKey, pageValidation } = props;
  const state = resolveState(props);

  if (isLoading) {
    return {
      state: StatusSidebarStatusType.Loading,
    };
  }
  if (pageKey === 'status') {
    const statusState =
      {
        [OrderDisplayBaseStatus.InfoNeeded]:
          StatusSidebarStatusTypeInfoNeededMixin.InfoNeeded,
        [OrderDisplayBaseStatus.PreApprovedInfoNeeded]:
          StatusSidebarStatusTypeInfoNeededMixin.PreApprovedInfoNeeded,
        [OrderDisplayBaseStatus.ReadyToSubmit]:
          StatusSidebarStatusTypeInfoNeededMixin.ReadyToSubmit,
        [OrderDisplayBaseStatus.Rejected]: StatusSidebarStatusType.Rejected,
        [OrderDisplayBaseStatus.TrainingResponse]:
          StatusSidebarStatusType.Rejected,
        [OrderDisplayBaseStatus.NotInUnitedStates]:
          StatusSidebarStatusType.Rejected,
        [OrderDisplayBaseStatus.OptedOut]: StatusSidebarStatusType.OptedOut,
        [OrderDisplayBaseStatus.Waitlist]: StatusSidebarStatusType.Waitlisted,
        [OrderDisplayBaseStatus.InReview]: StatusSidebarStatusType.Pending,
        [OrderDisplayBaseStatus.Pending]: StatusSidebarStatusType.Pending,
        [OrderDisplayBaseStatus.PendingPreTestConsult]:
          StatusSidebarStatusType.PreApproved,
        [OrderDisplayBaseStatus.PendingApproval]:
          StatusSidebarStatusType.Approved,
        [OrderTimelineDisplayStatus.KitPreparing]:
          StatusSidebarStatusType.Approved,
        [OrderTimelineDisplayStatus.KitMailed]:
          StatusSidebarStatusType.Approved,
        [OrderTimelineDisplayStatus.SamplesReceived]:
          StatusSidebarStatusType.Approved,
        [OrderTimelineDisplayStatus.AnalysisComplete]:
          StatusSidebarStatusType.Approved,
        [OrderTimelineDisplayStatus.SequencingComplete]:
          StatusSidebarStatusType.Approved,
        [OrderTimelineDisplayStatus.PendingConsultScheduling]:
          StatusSidebarStatusType.Approved,
        [OrderTimelineDisplayStatus.ConsultScheduled]:
          StatusSidebarStatusType.Approved,
        [OrderTimelineDisplayStatus.ReportReady]:
          StatusSidebarStatusType.Approved,
        [OrderDisplayBaseStatus.Approved]: StatusSidebarStatusType.Approved,
        [OrderVisitTimelineDisplayStatus.VisitPending]:
          StatusSidebarStatusType.Approved,
        [OrderVisitTimelineDisplayStatus.VisitLabResultsPending]:
          StatusSidebarStatusType.Approved,
        [OrderVisitTimelineDisplayStatus.VisitLabResultsComplete]:
          StatusSidebarStatusType.Approved,
      }?.[state] ?? StatusSidebarStatusTypeInfoNeededMixin.InfoNeeded;

    if (
      [
        StatusSidebarStatusTypeInfoNeededMixin.InfoNeeded,
        StatusSidebarStatusTypeInfoNeededMixin.PreApprovedInfoNeeded,
      ].includes(statusState as StatusSidebarStatusTypeInfoNeededMixin)
    ) {
      return {
        progress: 0,
        state: statusState,
      };
    }
    return {
      state: statusState as StatusSidebarStatusType,
    };
  }
  if (pageValidation?.completeness?.[pageKey] === 1) {
    return {
      state: StatusSidebarStatusType.Complete,
    };
  }
  return {
    state: StatusSidebarStatusTypeInfoNeededMixin.InfoNeeded,
    progress: pageValidation?.completeness?.[pageKey] ?? 0,
  };
}

export const ORDER_STATUS_ITEMS: SelectProps['items'] = [
  {
    key: OrderStatus.PendingPreTestConsult,
    label: 'Pending Pre-Test Consult',
    value: OrderStatus.PendingPreTestConsult,
  },
  {
    key: OrderStatus.PendingApproval,
    label: 'Pending Approval',
    value: OrderStatus.PendingApproval,
  },
  {
    key: OrderStatus.PWNApproved,
    label: 'PWN Approved',
    value: OrderStatus.PWNApproved,
  },
  {
    key: OrderStatus.NeedsKit,
    label: 'Needs Kit',
    value: OrderStatus.NeedsKit,
  },
  {
    key: OrderStatus.ManualLaboratoryRequest,
    label: 'Manual Laboratory Request',
    value: OrderStatus.ManualLaboratoryRequest,
  },
  {
    key: OrderStatus.KitMailed,
    label: 'Kit Mailed',
    value: OrderStatus.KitMailed,
  },
  {
    key: OrderStatus.SamplesReceived,
    label: 'Samples Received',
    value: OrderStatus.SamplesReceived,
  },
  {
    key: OrderStatus.SampleQc,
    label: 'Sample QC',
    value: OrderStatus.SampleQc,
  },
  {
    key: OrderStatus.ExomeQc,
    label: 'Exome QC',
    value: OrderStatus.ExomeQc,
  },
  {
    key: OrderStatus.SequencingComplete,
    label: 'Sequencing Complete',
    value: OrderStatus.SequencingComplete,
  },
  {
    key: OrderStatus.AnalysisComplete,
    label: 'Analysis Complete',
    value: OrderStatus.AnalysisComplete,
  },
  {
    key: OrderStatus.PendingConsultScheduling,
    label: 'Pending Consult Scheduling',
    value: OrderStatus.PendingConsultScheduling,
  },
  {
    key: OrderStatus.ConsultScheduled,
    label: 'Consult Scheduled',
    value: OrderStatus.ConsultScheduled,
  },
  {
    key: OrderStatus.ReportReady,
    label: 'Report Ready',
    value: OrderStatus.ReportReady,
  },
];
