import {
  extendVariants,
  Textarea as NextTextArea,
  TextAreaProps as _TextAreaProps,
} from '@heroui/react';
import { forwardRef, ForwardRefRenderFunction } from 'react';
import { TestIdMixin } from '../../types';

export interface TextAreaProps
  extends Omit<_TextAreaProps, 'variant' | 'size'>,
    TestIdMixin {
  variant?: _TextAreaProps['variant'] | 'flush';
  size?: _TextAreaProps['size'] | 'xl';
}

// @ts-ignore
export const Component: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  TextAreaProps
> = extendVariants(NextTextArea, {
  variants: {
    variant: {
      flat: {
        inputWrapper: [
          'bg-sandstone-200',
          'border-1 border-natural-black-200',
          'data-[hover=true]:bg-sandstone-400',
          'group-data-[focus=true]:bg-white',
          'group-data-[focus=true]:border-natural-black-600',
          'group-data-[invalid=true]:border-danger',
        ],
        input: ['placeholder:text-natural-black-400'],
      },
      flush: {
        inputWrapper: [
          'px-0',
          'bg-transparent shadow-none',
          'border-1 border-transparent',
          'data-[hover=true]:bg-white',
          'group-data-[focus=true]:bg-white',
          'group-data-[invalid=true]:border-danger',
        ],
        input: ['placeholder:text-natural-black-400', 'font-light'],
      },
    },
    size: {
      sm: {
        inputWrapper: ['min-h-[36px]'],
        input: ['text-sm sm:text-md'],
        label: 'text-sm font-light',
      },
      md: {
        inputWrapper: ['min-h-[44px]'],
        input: ['text-md sm:text-lg'],
        label: 'text-sm',
      },
      lg: {
        inputWrapper: ['min-h-[52px]'],
        input: ['text-lg sm:text-xl'],
        label: 'text-sm',
      },
      xl: {
        inputWrapper: ['min-h-[60px]'],
        input: ['text-xl sm:text-2xl'],
        label: 'text-sm',
      },
    },
  },
  defaultVariants: {
    size: 'lg',
    radius: 'sm',
    variant: 'flat',
    labelPlacement: 'outside',
  },
});

export const Textarea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (props, ref) => <Component ref={ref} {...props} />
);
