import {
  IS_DEV,
  SENTRY_DENY_URLS_LIST,
  SENTRY_IGNORE_ERRORS_LIST,
  SENTRY_SPAN_DENY_LIST,
} from '@/helpers/constants';
import * as Sentry from '@sentry/react';
import { ErrorEvent } from '@sentry/browser';
import { AxiosError, isAxiosError } from 'axios';
const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
const SENTRY_ENV = import.meta.env.VITE_SENTRY_ENV;

function addAxiosContextRecursive(
  event: ErrorEvent,
  error: unknown
): ErrorEvent {
  if (isAxiosError(error)) {
    return addAxiosContext(event, error);
  } else if (error instanceof Error && error.cause) {
    return addAxiosContextRecursive(event, error.cause);
  }
  return event;
}

function addAxiosContext(event: ErrorEvent, error: AxiosError) {
  if (error.response) {
    const contexts = { ...event.contexts };
    contexts.Axios = { Response: error.response };
    event.contexts = contexts;
  }
  return event;
}

export function initSentry() {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV || 'development',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.httpClientIntegration(),
      Sentry.extraErrorDataIntegration(),
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ['bearded-dragon'],
        behaviour: 'drop-error-if-contains-third-party-frames',
      }),
    ],
    tracesSampleRate: IS_DEV ? 1.0 : 0.5,
    profilesSampleRate: IS_DEV ? 1.0 : 0.5,
    sendDefaultPii: true,
    beforeSend: (event, hint) => {
      const status = event.contexts?.AxiosError?.status;
      if (status === 400 || status === 403) {
        // Ignore all 400 errors since they're almost all ValidationErrors.
        // Also ignore 403 errors since they are mostly going to be /user/me/ calls.
        return null;
      }
      return addAxiosContextRecursive(event, hint?.originalException);
    },
    beforeSendSpan(span) {
      if (SENTRY_SPAN_DENY_LIST.some((s) => span.description?.includes(s))) {
        return null;
      }
      return span;
    },
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/symptom-checker\.probablygenetic\.com\/api/,
      /^https:\/\/patient-replica\.probablygenetic\.com\/api/,
    ],
    ignoreErrors: SENTRY_IGNORE_ERRORS_LIST,
    denyUrls: SENTRY_DENY_URLS_LIST,
  });
}
