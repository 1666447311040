import {
  AutocompleteItemProps,
  TableProps as NextTableProps,
} from '@heroui/react';
import React, { ReactNode } from 'react';
import {
  FieldError,
  FieldErrorsImpl,
  Merge,
  UseFormReturn,
} from 'react-hook-form';
import { SelectProps } from '../../../patients/atoms';

export interface TableColumnProps<T extends TableRowProps> {
  key: string;
  label: string;
  value: string;
  allowListOperators?: SelectProps['items'];
  items: AutocompleteItemProps[]; // possible values to be displayed in search and select
  renderValue?: (row: T) => ReactNode;
  defaultValue?: ReactNode; // default value to be displayed in cell if no value is found
}

export enum LogicalOperator {
  And = 'and',
  Or = 'or',
}

export enum Operator {
  Equals = '=',
  NotEquals = '≠',
  Contains = '∋',
  NotContains = '∌',
  IsSet = '✓',
  NotSet = '✗',
  LessThan = '<',
  GreaterThan = '>',
  LessThanOrEqual = '≤',
  GreaterThanOrEqual = '≥',
}

export interface FilterCondition {
  columnId: string | null; // db column
  operator: Operator; // future-proof. For now only supports =
  value: string | number;
}

export interface FilterGroup {
  operator: LogicalOperator;
  filters: FilterCondition[]; // explicitly only allow FilterCondition at second level
}

export interface RootFilterGroup {
  operator: LogicalOperator;
  filters: FilterGroup[]; // explicitly only allow FilterGroup at top level
}

export interface TableRowProps extends Record<string, any> {
  key: string | number;
}

export interface TableProps<T extends TableRowProps> extends NextTableProps {
  title?: string;
  subtitle?: string;
  rowsPerPage?: number;
  totalRowsCount?: number;
  page?: number;
  onPageChange?: (nextPage: number) => void;
  isLoading?: boolean;
  columns: TableColumnProps<T>[];
  rows: T[];
  rowKey?: string;
  rootFilters: RootFilterGroup;
  onFiltersChange: (filters: RootFilterGroup) => void;
  noun?: string; // patient
}

export interface TableFiltersProps<T extends TableRowProps>
  extends Omit<
    TableFiltersGroupProps<T>,
    'errors' | 'filterGroup' | 'filterGroupIndex'
  > {
  // deeply nested map from root filter group
  errors?: Merge<FieldError, FieldErrorsImpl<RootFilterGroup>>;
}

export interface TableFiltersGroupProps<T extends TableRowProps>
  extends Omit<
    TableFiltersConditionProps<T>,
    'errors' | 'filterCondition' | 'filterConditionIndex'
  > {
  onApplyFilters: () => void;
  isAppliable: boolean;
  errors?: Merge<FieldError, FieldErrorsImpl<FilterGroup>>;
}

export interface TableFiltersConditionProps<T extends TableRowProps>
  extends Pick<
    TableProps<T>,
    'rootFilters' | 'onFiltersChange' | 'columns' | 'isLoading'
  > {
  filterGroup: FilterGroup;
  filterGroupIndex: number;
  filterCondition: FilterCondition;
  filterConditionIndex: number;
  errors?: Merge<FieldError, FieldErrorsImpl<FilterCondition>>;
  formMethods?: UseFormReturn<any, any>;
}

export enum AgeRange {
  Unknown = 'unknown',
  Range_0_5 = '0-5',
  Range_5_10 = '5-10',
  Range_10_15 = '10-15',
  Range_15_20 = '15-20',
  Range_20_25 = '20-25',
  Range_25_30 = '25-30',
  Range_30_35 = '30-35',
  Range_35_40 = '35-40',
  Range_40_45 = '40-45',
  Range_45_50 = '45-50',
  Range_50_55 = '50-55',
  Range_55_60 = '55-60',
  Range_60_65 = '60-65',
  Range_65_70 = '65-70',
  Range_70_75 = '70-75',
  Range_75_80 = '75-80',
  Range_80_85 = '80-85',
  Range_85_90 = '85-90',
  Range_90_Plus = '90+',
}

export enum PGSequencingTestResult {
  Unknown = 'Unknown',
  Negative = 'Negative',
  Positive = 'Positive',
  Carrier = 'carrier',
  Unclassified = 'Unclassified',
  PotentiallyPositive = 'Potentially_positive',
  Uncertain = 'Uncertain',
}
