import {
  DisplayStatusRenderPayload,
  OrderDisplayBaseStatus,
  OrderStatus,
  OrderStepRenderPayload,
  OrderTimelineDisplayStatus,
  OrderVisitTimelineDisplayStatus,
  ReportReadyStatus,
  StatusSidebarStatusType,
  StatusSidebarStatusTypeInfoNeededMixin,
} from './StatusPage.types';
import { paths } from '../../../paths';
import { Button } from '../../atoms';
import { Link } from '@heroui/react';
import { downloadData, downloadReport } from './StatusCard/download-report.api';
import { PiSealCheckFill, PiXCircleFill } from 'react-icons/pi';
import { RiErrorWarningLine } from 'react-icons/ri';
import {
  getFirstIncompleteSectionPathFromPageValidation,
  getHumanReadableMissingInformation,
} from './StatusPage.utils';
import { HiDotsCircleHorizontal } from 'react-icons/hi';
import dayjs from 'dayjs';

export const STATUS_TYPE_TO_STATUS_ICON_MAP = {
  [StatusSidebarStatusTypeInfoNeededMixin.InfoNeeded]: {
    className: 'bg-blush-200',
    title: 'Info needed',
  },
  [StatusSidebarStatusTypeInfoNeededMixin.PreApprovedInfoNeeded]: {
    className: 'bg-robins-egg-blue-200',
    title: 'Pre-Approved',
  },
  [StatusSidebarStatusTypeInfoNeededMixin.ReadyToSubmit]: {
    className: 'bg-sea-green-200',
    title: 'Ready to submit',
  },
  [StatusSidebarStatusType.Waitlisted]: {
    className: 'bg-sandstone-1000',
    title: 'Waitlisted',
  },
  [StatusSidebarStatusType.OptedOut]: {
    className: 'bg-natural-black-200',
    title: 'Opted out',
  },
  [StatusSidebarStatusType.Pending]: {
    className: 'bg-peony-200',
    title: 'Pending',
  },
  [StatusSidebarStatusType.PreApproved]: {
    className: 'bg-peach-200',
    title: 'Approved',
  },
  [StatusSidebarStatusType.Approved]: {
    className: 'bg-robins-egg-blue-1000',
    title: 'Approved',
  },
  [StatusSidebarStatusType.Rejected]: {
    className: 'bg-orchid-400',
    title: 'Waitlisted',
  },
  [StatusSidebarStatusType.Loading]: {
    className: 'bg-transparent',
    title: '',
  },
  [StatusSidebarStatusType.Complete]: {
    className: 'bg-transparent',
    title: '',
  },
};
export const COPY_MAP: Record<string, DisplayStatusRenderPayload> = {
  [OrderDisplayBaseStatus.InfoNeeded]: {
    icon: {
      component: <RiErrorWarningLine />,
      title: 'Info needed',
      className: 'text-blush-1000',
    },
    classNames: {
      base: 'bg-blush-200',
      body: 'text-navy-1000',
    },
    getTitle: (
      <>
        Information is needed to fulfill the order
        <p className="text-xl font-normal text-navy-1000">
          See if you qualify for <span className="font-bold">no-cost</span>{' '}
          genetic testing
        </p>
      </>
    ),
    getDescription: ({ pageValidation, formResponseId, navigate }) => {
      let path =
        getFirstIncompleteSectionPathFromPageValidation(pageValidation);
      const missingInformation =
        getHumanReadableMissingInformation(pageValidation);

      // TODO: Workaround until symptom assessment edit page is implemented
      if (path.includes('assessment')) {
        path = paths.flow.symptomAssessment.getHref(formResponseId);
      }

      return (
        <>
          <div className="flex flex-col gap-2">
            <span className="font-bold">More info needed</span>
            <p>
              We just need a bit more information to finalize your assessment.
              Provide the missing items below to see if you qualify for a free,
              no-cost genetic test and get connected to potential treatment
              options.
            </p>
            <ul className="list-disc ml-8">
              {Object.entries(missingInformation).map(([key, message]) => (
                <li key={key}>{message}</li>
              ))}
            </ul>
          </div>
          <Button
            color="primary"
            size="lg"
            className="my-4 px-4"
            onPress={() => navigate?.(path)}
          >
            Continue your assessment
          </Button>
        </>
      );
    },
  },
  [OrderDisplayBaseStatus.PreApprovedInfoNeeded]: {
    icon: {
      component: <RiErrorWarningLine />,
      title: 'Info needed',
      className: 'text-blush-1000',
    },
    classNames: {
      base: 'bg-robins-egg-blue-200',
      body: 'text-navy-1000',
    },
    getTitle: (
      <>You’ve been pre-approved as eligible for no-cost genetic testing.</>
    ),
    getDescription: ({ pageValidation, formResponseId, navigate }) => {
      let path =
        getFirstIncompleteSectionPathFromPageValidation(pageValidation);
      const missingInformation =
        getHumanReadableMissingInformation(pageValidation);

      // TODO: Workaround until symptom assessment edit page is implemented
      if (path.includes('assessment')) {
        path = paths.flow.symptomAssessment.getHref(formResponseId);
      }

      return (
        <>
          <div className="flex flex-col gap-2">
            <span className="font-bold">
              You are missing information for us to complete your order
            </span>
            <p>
              We just need a bit more information to finalize your order.
              Provide the missing items below to get your free, no-cost genetic
              test and get connected to potential treatment options.
            </p>
            <ul className="list-disc ml-8">
              {Object.entries(missingInformation).map(([key, message]) => (
                <li key={key}>{message}</li>
              ))}
            </ul>
          </div>
          <Button
            color="primary"
            size="lg"
            className="my-4 px-4"
            onPress={() => navigate?.(path)}
          >
            Continue your assessment
          </Button>
        </>
      );
    },
  },
  [OrderDisplayBaseStatus.ReadyToSubmit]: {
    classNames: {
      base: 'bg-sea-green-200',
      body: 'text-navy-1000',
    },
    getTitle: <>Your application is ready for submission.</>,
    getDescription: ({ formResponseId, navigate }) => {
      return (
        <>
          <div className="flex flex-col gap-2">
            <p>
              Great work! You’ve completed all the required steps. Now, take a
              moment to review your application to ensure everything looks
              accurate. Once you’re ready, you’ll be able to submit it and
              receive your no-cost genetic test.
            </p>
          </div>
          <Button
            color="primary"
            size="lg"
            className="my-4 px-4"
            onPress={() =>
              navigate?.(paths.review.root.getHref(formResponseId))
            }
          >
            Review and submit application
          </Button>
        </>
      );
    },
  },
  [OrderDisplayBaseStatus.OptedOut]: {
    classNames: {
      base: 'bg-natural-black-200',
    },
    getTitle: 'Opted out',
    getDescription: () => 'You have opted out',
  },
  [OrderDisplayBaseStatus.Rejected]: {
    classNames: {
      base: 'bg-orchid-400',
      body: 'text-navy-1000',
    },
    getTitle: 'Thank you for your interest',
    getDescription: () => (
      <>
        <p>
          After reviewing your information, we’ve determined that you do not
          qualify for the conditions we are assisting with. Unfortunately, this
          means you’re not eligible for a free genetic test at this time. We
          appreciate your time and interest in our program.
        </p>
        <span>
          If you would like to know more about how eligibility criteria is
          determined, please visit{' '}
          <Link
            className={'text-xl'}
            isExternal
            href={'https://www.probablygenetic.com/faq'}
          >
            https://www.probablygenetic.com/faq
          </Link>
          .
        </span>
      </>
    ),
  },
  [OrderDisplayBaseStatus.InReview]: {
    icon: {
      component: <HiDotsCircleHorizontal />,
      title: 'Pending',
      className: 'text-natural-black-600',
    },
    classNames: {
      base: 'bg-peony-200',
    },
    getTitle: 'Under Review',
    getDescription: () =>
      "Thank you for your submission! Our team is carefully reviewing your information and will notify you by email when there's an update. You can also track your status anytime by logging into your profile.",
  },
  [OrderDisplayBaseStatus.TrainingResponse]: {
    classNames: {
      base: 'bg-peach-200',
    },
    getTitle: 'Thank you for your submission',
    getDescription: () =>
      'Your submission will be used to help people living with undiagnosed symptoms access free genetic testing. We really appreciate the help.',
  },
  [OrderDisplayBaseStatus.NotInUnitedStates]: {
    icon: {
      component: <PiXCircleFill />,
      title: 'Waitlisted',
      className: 'text-blush-1000',
    },
    classNames: {
      base: 'bg-blush-200',
      body: 'text-navy-1000',
    },
    getTitle: 'Thank you for your submission',
    getDescription: () =>
      "We regret to inform you that we currently don't offer testing in your country. We are constantly expanding to new regions and will reach back out to you as soon as testing becomes available in your country. Thank you for your time.",
  },
  [OrderDisplayBaseStatus.PendingPreTestConsult]: {
    icon: {
      component: <PiSealCheckFill />,
      title: 'Approved',
      className: 'text-sea-green-1000',
    },
    classNames: {
      base: 'bg-peach-200',
      body: 'text-navy-1000',
    },
    getTitle: 'You’re eligible for a free consultation.',
    getDescription: ({ order }) => (
      <>
        {order?.pwnPreTestConsultDt ? (
          <>
            <p>
              Your consultation with a board certified genetic counselor is
              scheduled for{' '}
              {dayjs(order.pwnPreTestConsultDt).format('MMMM D, YYYY h:mm A')}.
            </p>
            {order?.pwnPreTestConsultLink ? (
              <Button
                color="primary"
                size="lg"
                className="my-4 px-4"
                onPress={() => {
                  window.open(String(order.pwnPreTestConsultLink), '_blank');
                }}
              >
                Reschedule
              </Button>
            ) : (
              <Button
                color="primary"
                size="lg"
                className="my-4 px-4"
                onPress={() => {
                  window.open('mailto:hello@probablygenetic.com');
                }}
              >
                Email us to reschedule
              </Button>
            )}
          </>
        ) : (
          <>
            <p>
              Thank you for your submission! You’re eligible for a free
              consultation with a genetic counselor. To proceed with your free
              genetic test, please schedule a free consultation with a genetic
              counselor.
            </p>
            <p>
              If you have any problem with the automatic scheduling or any
              question regarding the counselor session, please reach out to us
              at{' '}
              <Link
                isExternal
                className="text-xl"
                href="mailto:hello@probablygenetic.com"
              >
                hello@probablygenetic.com
              </Link>
              .
            </p>
            {order?.pwnPreTestConsultLink ? (
              <Button
                color="primary"
                size="lg"
                className="my-4 px-4"
                onPress={() => {
                  window.open(String(order.pwnPreTestConsultLink), '_blank');
                }}
              >
                Schedule your genetic counseling session
              </Button>
            ) : (
              <Button
                color="primary"
                size="lg"
                className="my-4 px-4"
                onPress={() => {
                  window.open('mailto:hello@probablygenetic.com');
                }}
              >
                Email us to schedule
              </Button>
            )}
          </>
        )}
      </>
    ),
  },
  [OrderDisplayBaseStatus.Approved]: {
    icon: {
      component: <PiSealCheckFill />,
      title: 'Approved',
      className: 'text-sea-green-1000',
    },
    classNames: {
      base: 'bg-orchid-400',
      body: 'text-navy-1000',
    },
    getTitle: 'Your genetic test has been ordered!',
    getDescription: () =>
      'Thank you for completing the questionnaire. A physician will review your information before the next step.',
  },
  [OrderDisplayBaseStatus.PendingApproval]: {
    icon: {
      component: <PiSealCheckFill />,
      title: 'Approved',
      className: 'text-sea-green-1000',
    },
    classNames: {
      base: 'bg-orchid-400',
      body: 'text-navy-1000',
    },
    getTitle: 'Your genetic test has been ordered!',
    getDescription: () =>
      'Thank you for completing the questionnaire. A physician will review your information before the next step.',
  },
  [OrderTimelineDisplayStatus.KitPreparing]: {
    icon: {
      component: <PiSealCheckFill />,
      title: 'Approved',
      className: 'text-natural-black-800',
    },
    classNames: {
      base: 'bg-robins-egg-blue-1000 text-black',
      header: 'text-black',
      footer: 'text-natural-black-800',
    },
    getTitle: 'Your genetic test has been ordered!',
    getDescription: () => '',
  },
  [OrderTimelineDisplayStatus.KitMailed]: {
    icon: {
      component: <PiSealCheckFill />,
      title: 'Approved',
      className: 'text-natural-black-800',
    },
    classNames: {
      base: 'bg-robins-egg-blue-1000 text-black',
      header: 'text-black',
      footer: 'text-natural-black-800',
    },
    getTitle: 'Your genetic test has been shipped!',
    getDescription: () => '',
  },
  [OrderTimelineDisplayStatus.SamplesReceived]: {
    icon: {
      component: <PiSealCheckFill />,
      title: 'Approved',
      className: 'text-natural-black-800',
    },
    classNames: {
      base: 'bg-robins-egg-blue-1000 text-black',
      header: 'text-black',
      footer: 'text-natural-black-800',
    },
    getTitle: 'Your samples are being processed!',
    getDescription: () => '',
  },
  [OrderTimelineDisplayStatus.SequencingComplete]: {
    icon: {
      component: <PiSealCheckFill />,
      title: 'Approved',
      className: 'text-natural-black-800',
    },
    classNames: {
      base: 'bg-robins-egg-blue-1000 text-black',
      header: 'text-black',
      footer: 'text-natural-black-800',
    },
    getTitle: 'Your samples are being sequenced!',
    getDescription: () => '',
  },
  [OrderTimelineDisplayStatus.AnalysisComplete]: {
    icon: {
      component: <PiSealCheckFill />,
      title: 'Approved',
      className: 'text-natural-black-800',
    },
    classNames: {
      base: 'bg-robins-egg-blue-1000 text-black',
      header: 'text-black',
      footer: 'text-natural-black-800',
    },
    getTitle: 'Your samples are being analyzed!',
    getDescription: () => '',
  },
  [OrderTimelineDisplayStatus.PendingConsultScheduling]: {
    icon: {
      component: <PiSealCheckFill />,
      title: 'Approved',
      className: 'text-natural-black-800',
    },
    classNames: {
      base: 'bg-robins-egg-blue-1000 text-black',
      header: 'text-black',
      footer: 'text-natural-black-800',
    },
    getTitle: 'Your results are ready!',
    getDescription: () => '',
  },
  [OrderTimelineDisplayStatus.ConsultScheduled]: {
    icon: {
      component: <PiSealCheckFill />,
      title: 'Approved',
      className: 'text-natural-black-800',
    },
    classNames: {
      base: 'bg-robins-egg-blue-1000 text-black',
      header: 'text-black',
      footer: 'text-natural-black-800',
    },
    getTitle: 'Your results are ready!',
    getDescription: () => '',
  },
  [OrderTimelineDisplayStatus.ReportReady]: {
    icon: {
      component: <PiSealCheckFill />,
      title: 'Approved',
      className: 'text-natural-black-800',
    },
    classNames: {
      base: 'bg-robins-egg-blue-1000 text-black',
      header: 'text-black',
      footer: 'text-natural-black-800',
    },
    getTitle: 'Your results are ready!',
    getDescription: () => '',
  },
  [OrderVisitTimelineDisplayStatus.VisitPending]: {
    icon: {
      component: <HiDotsCircleHorizontal />,
      title: 'Pending',
      className: 'text-natural-black-800',
    },
    classNames: {
      base: 'bg-robins-egg-blue-1000 text-black',
      header: 'text-black',
      footer: 'text-natural-black-800',
    },
    getTitle: 'Your lab visit is pending!',
    getDescription: () => (
      <>
        <p>
          Visit a LabCorp facility for your service. Walk in anytime—no
          appointment needed—or schedule one in advance for added convenience.
        </p>
        <Button
          color="primary"
          size="lg"
          className="my-4 px-4"
          onPress={() => {
            window.open(
              'https://www.labcorp.com/labs-and-appointments',
              '_blank'
            );
          }}
        >
          Schedule an appointment
        </Button>
      </>
    ),
  },
  [OrderVisitTimelineDisplayStatus.VisitLabResultsPending]: {
    icon: {
      component: <HiDotsCircleHorizontal />,
      title: 'Pending',
      className: 'text-natural-black-800',
    },
    classNames: {
      base: 'bg-robins-egg-blue-1000 text-black',
      header: 'text-black',
      footer: 'text-natural-black-800',
    },
    getTitle: 'Your lab results are being processed!',
    getDescription: () =>
      'Your results are being processed. We’ll notify you as soon as they are ready. Thank you for your patience.',
  },
  [OrderVisitTimelineDisplayStatus.VisitLabResultsComplete]: {
    icon: {
      component: <PiSealCheckFill />,
      title: 'Complete',
      className: 'text-natural-black-800',
    },
    classNames: {
      base: 'bg-robins-egg-blue-1000 text-black',
      header: 'text-black',
      footer: 'text-natural-black-800',
    },
    getTitle: 'Your lab results are ready!',
    getDescription: () => 'Your lab results are available.',
  },
};

export const ORDER_VISIT_STEPS_MAP: Record<string, OrderStepRenderPayload> = {
  [OrderVisitTimelineDisplayStatus.VisitPending]: {
    key: OrderVisitTimelineDisplayStatus.VisitPending,
    title: 'Visit Pending',
    getDescription: () =>
      'Your visit is pending. Stop by any LabCorp facility to complete your service at your convenience.',
  },
  [OrderVisitTimelineDisplayStatus.VisitLabResultsPending]: {
    key: OrderVisitTimelineDisplayStatus.VisitLabResultsPending,
    title: 'Lab Results Pending',
    getDescription: () =>
      'Your lab results are being reviewed. This may take some time—please check back soon.',
  },
  [OrderVisitTimelineDisplayStatus.VisitLabResultsComplete]: {
    key: OrderVisitTimelineDisplayStatus.VisitLabResultsComplete,
    title: 'Lab Results Complete',
    getDescription: () => 'Your lab results are available.',
  },
};

export const ORDER_STEPS_MAP: Record<string, OrderStepRenderPayload> = {
  [OrderTimelineDisplayStatus.KitPreparing]: {
    key: OrderTimelineDisplayStatus.KitPreparing,
    title: 'Doctor Review',
    getDescription: () => 'A provider from PWNHealth is reviewing your order.',
  },
  [OrderTimelineDisplayStatus.KitMailed]: {
    key: OrderTimelineDisplayStatus.KitMailed,
    title: 'Kit Shipped',
    getDescription: () => 'The test kit has been shipped to your address.',
  },
  [OrderTimelineDisplayStatus.SamplesReceived]: {
    key: OrderTimelineDisplayStatus.SamplesReceived,
    title: 'Kit Returned',
    getDescription: () => 'The lab has received your sample.',
  },
  [OrderTimelineDisplayStatus.SequencingComplete]: {
    key: OrderTimelineDisplayStatus.SequencingComplete,
    title: 'Sequencing DNA',
    getDescription: () =>
      'The lab is sequencing your DNA, this takes 3-5 weeks.',
  },
  [OrderTimelineDisplayStatus.AnalysisComplete]: {
    key: OrderTimelineDisplayStatus.AnalysisComplete,
    title: 'Analyzing DNA',
    getDescription: () =>
      'The lab is analyzing your DNA, this takes 2-3 weeks.',
  },
  [OrderTimelineDisplayStatus.ReportReady]: {
    key: OrderTimelineDisplayStatus.ReportReady,
    title: 'Your results are ready!',
    getDescription: ({ order, formResponseId, url }) => {
      return (
        {
          [OrderStatus.PendingConsultScheduling]: (
            <>
              <p>
                Please schedule a consultation with a board-certified genetic
                counselor so we may review them with you. Please reach out to us
                to schedule some time with our genetic counselor.
              </p>
              {order?.pwnConsultLink ? (
                <Button
                  color="primary"
                  size="lg"
                  className="my-4 px-4"
                  onPress={() => {
                    window.open(String(order.pwnConsultLink), '_blank');
                  }}
                >
                  Schedule a consultation
                </Button>
              ) : (
                <Button
                  color="primary"
                  size="lg"
                  className="my-4 px-4"
                  onPress={() => {
                    window.open('mailto:hello@probablygenetic.com');
                  }}
                >
                  Email us for a consultation
                </Button>
              )}
            </>
          ),
          [OrderStatus.ConsultScheduled]: (
            <>
              {order?.pwnConsultDt && (
                <p>
                  Your consultation with a board certified genetic counselor to
                  review your results is scheduled for{' '}
                  {dayjs(order.pwnConsultDt).format('MMMM D, YYYY h:mm A')}.
                </p>
              )}
              {order?.pwnConsultLink ? (
                <Button
                  color="primary"
                  size="lg"
                  className="my-4 px-4"
                  onPress={() => {
                    window.open(String(order.pwnConsultLink), '_blank');
                  }}
                >
                  Reschedule
                </Button>
              ) : (
                <Button
                  color="primary"
                  size="lg"
                  className="my-4 px-4"
                  onPress={() => {
                    window.open('mailto:hello@probablygenetic.com');
                  }}
                >
                  Email us to reschedule
                </Button>
              )}
            </>
          ),
          [OrderStatus.ReportReady]: (() => {
            const downloadableKit = order?.kits
              ?.flatMap((kit) => kit.samples)
              ?.at(-1);

            return (
              <>
                <p>
                  Your report
                  {downloadableKit?.vcfFileAvailable && ' and sample data'} is
                  ready for download as a pdf below.
                </p>
                <div className="flex flex-row gap-4">
                  <Button
                    color="primary"
                    size="lg"
                    className="my-4 px-4"
                    onPress={async () => {
                      const reportUrl = await downloadReport(
                        {
                          input: { formResponseId },
                        },
                        url
                      );
                      window.open(reportUrl, '_blank');
                    }}
                  >
                    Download your report
                  </Button>
                  {downloadableKit?.vcfFileAvailable && (
                    <Button
                      color="primary"
                      size="lg"
                      className="my-4 px-4"
                      onPress={async () => {
                        const dataUrl = await downloadData(
                          {
                            input: {
                              formResponseId,
                              sampleId: String(downloadableKit.id),
                            },
                          },
                          url
                        );
                        window.open(dataUrl, '_blank');
                      }}
                    >
                      Download raw data
                    </Button>
                  )}
                </div>
              </>
            );
          })(),
        }[order?.status as ReportReadyStatus] ||
        'The results of your genetic sequencing are ready.'
      );
    },
  },
};
