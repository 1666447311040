import { COUNTRIES } from '@/helpers/countries';
import { SelectFieldItem } from '@pg-web/bearded-dragon-ui';

export const IS_DEV = import.meta.env.DEV;
export const IS_TEST = import.meta.env.VITE_ENV === 'test';
export const IS_STAGING =
  import.meta.env.PROD && import.meta.env.VITE_ENV === 'stg';
export const IS_PROD =
  import.meta.env.PROD && import.meta.env.VITE_ENV === 'prd';

// NOTE: This _ends_ with a `/` already.
export const API_URL = import.meta.env.VITE_API_URL;

export const GRAPHQL_API_ENDPOINT = `${API_URL}graphql/`;

export const SEQUENCING_API_URL = import.meta.env.VITE_SEQUENCING_API_URL;

export const PG_HOME_URL = 'https://www.probablygenetic.com';

export const INTAKE_FORM_DISCLAIMER = `If you continue, we will ask you questions about your and your family's medical history and other health information. We use this information you provide to evaluate your eligibility for genetic testing. By clicking "Create an account", you authorize us to collect the health information you provide to us.`;

export const PRIVACY_POLICY_URL =
  'https://www.probablygenetic.com/legal/website-privacy-policy';

export const SENTRY_SPAN_DENY_LIST = [
  'rudderstack.com',
  'rudderlabs.com',
  'gravatar.com',
  'cloudfront.net/', // posthog reverse proxy
  'cookiebot.com',
  'posthog.com',
];

export const SENTRY_DENY_URLS_LIST = [
  // Facebook flakiness
  /graph\.facebook\.com/i,
  // Facebook blocked
  /connect\.facebook\.net\/en_US\/all\.js/i,
  // Woopra flakiness
  /eatdifferent\.com\.woopra-ns\.com/i,
  /static\.woopra\.com\/js\/woopra\.js/i,
  // Chrome extensions
  /extensions\//i,
  /^chrome:\/\//i,
  /^chrome-extension:\/\//i,
  // Other plugins
  /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
  /webappstoolbarba\.texthelp\.com\//i,
  /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  /consentcdn\.cookiebot\.com/i,
  /posthog\.com/i,
  /rudderstack\.com/i,
];

export const SENTRY_IGNORE_ERRORS_LIST = [
  // Sentry community
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
  // reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  'withrealtime/messaging',
  'Chartboost is not defined',
  "Can't find variable: Chartboost",
  // Potentially a posthog issue
  "TypeError: Failed to execute 'getComputedStyle'",
  // PG Custom
  'Multiple faces detected in the photo provided',
  'Unable to detect a face in the photo provided',
];

export enum RelationshipToPatient {
  Self = 'self',
  FamilyMember = 'family_member',
  Parent = 'parent',
}

export enum BiologicalSex {
  Male = 'Male',
  Female = 'Female',
  // OTHER = 'OTHER',
}

export enum PatientEthnicity {
  NATIVE_AMERICAN = 'NATIVE_AMERICAN',
  ASIAN = 'ASIAN',
  BLACK = 'BLACK',
  HISPANIC = 'HISPANIC',
  MIDDLE_EASTERN = 'MIDDLE_EASTERN',
  PACIFIC_ISLANDER = 'PACIFIC_ISLANDER',
  WHITE = 'WHITE',
  OTHER = 'OTHER',
}

export const RELATIONSHIP_OPTIONS = [
  {
    key: RelationshipToPatient.Self,
    value: RelationshipToPatient.Self,
    label: 'Self',
  },
  {
    key: RelationshipToPatient.Parent,
    value: RelationshipToPatient.Parent,
    label: 'Parent',
  },
  {
    key: RelationshipToPatient.FamilyMember,
    value: RelationshipToPatient.FamilyMember,
    label: 'Family Member',
  },
];

export const COUNTRIES_OPTIONS = COUNTRIES.map((country) => ({
  key: country,
  value: country,
  label: country,
}));

export const ETHNICITY_OPTIONS: SelectFieldItem[] = [
  {
    key: 'NATIVE_AMERICAN',
    label: 'American Indian or Alaska Native',
    value: 'NATIVE_AMERICAN',
  },
  { key: 'ASIAN', label: 'Asian', value: 'asian' },
  { key: 'BLACK', label: 'Black or African American', value: 'black' },
  { key: 'HISPANIC', label: 'Hispanic or Latino', value: 'hispanic' },
  {
    key: 'MIDDLE_EASTERN',
    label: 'Middle Eastern or North African',
    value: 'MIDDLE_EASTERN',
  },
  {
    key: 'PACIFIC_ISLANDER',
    label: 'Native Hawaiian or Other Pacific Islander',
    value: 'PACIFIC_ISLANDER',
  },
  { key: 'WHITE', label: 'White', value: 'WHITE' },
  { key: 'OTHER', label: 'Other', value: 'OTHER' },
];

export const BIOLOGICAL_SEX_OPTIONS = [
  {
    key: BiologicalSex.Female,
    value: BiologicalSex.Female,
    label: 'Female',
  },
  {
    key: BiologicalSex.Male,
    value: BiologicalSex.Male,
    label: 'Male',
  },
];

export const TOTAL_NUMBER_OF_CHAT_QUESTIONS = 20;

export const POA_INFO_TEXT =
  'If you’re submitting for another individual or a child over 18, proof of medical power of attorney will be required if the application is approved for testing.';
