import { AppProvider } from './provider';

// This is the component that bootstraps all other React components.
// All the components and other logic will live in the `AppProvider` component
// and the router definition file in `router.tsx`.

const App = () => {
  return <AppProvider />;
};

export default App;
