import { Line } from 'react-chartjs-2';
import {
  CategoryScale,
  Chart,
  ChartData,
  ChartOptions,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { DeepPartial } from 'chart.js/types/utils';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import merge from 'lodash.merge';
import { Spinner } from '@heroui/react';

dayjs.extend(localeData);

Chart.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export interface LineChartProps {
  title?: string;
  options?: DeepPartial<ChartOptions<'line'>>;
  data?: ChartData<'line'>;
  isLoading?: boolean;
}

export function LineChart({
  title,
  options: customOptions,
  data: customData,
  isLoading,
  ...props
}: LineChartProps) {
  const options: ChartOptions = merge(
    {
      backgroundColor: '#b4b1c2',
      color: 'black',
      responsive: true,
      plugins: {
        legend: {
          position: 'right',
          display: false,
        },
        title: {
          font: {
            weight: 'bold',
            size: 24,
            family: 'P22Underground',
          },
          fullSize: true,
          padding: 16,
          display: !!title,
          text: title,
        },
      },
    },
    customOptions
  );
  const data = {
    ...customData,
    datasets: customData?.datasets || [],
  };

  return isLoading ? (
    <Spinner size="lg" />
  ) : (
    <Line data={data} options={options} {...props} />
  );
}
