import {
  extendVariants,
  DatePicker as NextDatePicker,
  DatePickerProps as _DatePickerProps,
  DateValue,
  cn,
} from '@heroui/react';
import { forwardRef, ForwardRefRenderFunction, useState } from 'react';
import { today, getLocalTimeZone } from '@internationalized/date';
import { TestIdMixin } from '../../../types';
import './DatePicker.scss';

const now = today(getLocalTimeZone());

export interface DatePickerProps extends _DatePickerProps, TestIdMixin {}

// Due to a existing bug in HeroUI, slot styles aren't passed to the component correctly. Use scss until this is fixed
// https://github.com/heroui-inc/heroui/issues/2895
// @ts-ignore
const Component: ForwardRefRenderFunction<HTMLElement, DatePickerProps> =
  extendVariants(NextDatePicker, {
    variants: {
      size: {
        sm: {
          inputWrapper: [
            'h-[36px] min-h-[36px]',
            'bg-sandstone-200',
            'border-1 border-natural-black-200',
            'data-[hover=true]:bg-sandstone-400',
            'group-data-[focus=true]:bg-white',
            'group-data-[focus=true]:border-natural-black-600',
            'group-data-[invalid=true]:border-danger',
            'group-data-[invalid=true]:!bg-white',
          ],
          label: 'text-sm font-light',
        },
        md: {
          inputWrapper: [
            'h-[44px] min-h-[44px]',
            'bg-sandstone-200',
            'border-1 border-natural-black-200',
            'data-[hover=true]:bg-sandstone-400',
            'group-data-[focus=true]:bg-white',
            'group-data-[focus=true]:border-natural-black-600',
            'group-data-[invalid=true]:border-danger',
            'group-data-[invalid=true]:!bg-white',
          ],
          label: 'text-sm font-light',
        },
        lg: {
          inputWrapper: [
            'h-[52px] min-h-[52px]',
            'bg-sandstone-200',
            'border-1 border-natural-black-200',
            'data-[hover=true]:bg-sandstone-400',
            'group-data-[focus=true]:bg-white',
            'group-data-[focus=true]:border-natural-black-600',
            'group-data-[invalid=true]:border-danger',
            'group-data-[invalid=true]:!bg-white',
          ],
          label: 'text-sm font-light',
        },
      },
    },
    defaultVariants: {
      size: 'lg',
      radius: 'sm',
      labelPlacement: 'outside',
      showMonthAndYearPickers: 'true',
      validationBehavior: 'aria',
    },
  });

export const DatePicker = forwardRef<HTMLElement, DatePickerProps>(
  (props, ref) => {
    // Hacky keep focus state because of NextUI bug above
    const [focus, setFocus] = useState(false);

    return (
      <Component
        ref={ref}
        {...props}
        className={cn(
          'date-picker',
          {
            sm: 'date-picker-sm',
            md: 'date-picker-md',
            lg: 'date-picker-lg',
          }[props.size || 'lg']
        )}
        isDateUnavailable={(date: DateValue) => date.compare(now) >= 0}
        maxValue={now}
        visibleMonths={1}
        popoverProps={{ offset: 24, placement: 'bottom-end' }}
        data-invalid={props.isInvalid}
        data-focus={focus}
        onFocusChange={setFocus}
      />
    );
  }
);
