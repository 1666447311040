export const biotechPaths = {
  root: {
    path: '',
  },
  home: {
    path: '/',
    getHref: () => '/',
  },
  // Dupe/alias of `home` above
  signIn: {
    path: '/',
    getHref: () => '/',
  },
  signUp: {
    path: '/sign-up',
    getHref: () => '/sign-up',
  },
  dashboard: {
    root: {
      path: '/dashboard',
      getHref: () => '/dashboard',
    },
    overview: {
      path: '',
      getHref: () => '/dashboard',
    },
    patients: {
      path: 'patients',
      getHref: () => '/dashboard/patients',
    },
    targetPatients: {
      path: 'target-patients',
      getHref: () => '/dashboard/target-patients',
    },
    physicians: {
      path: 'physicians',
      getHref: () => '/dashboard/physicians',
    },
  },
};

export const paths = {
  root: {
    path: '',
  },
  home: {
    path: '/',
    getHref: () => '/',
  },
  // Dupe/alias of `home` above
  welcome: {
    path: '/',
    getHref: () => '/',
  },
  welcomeWithReferral: {
    path: '/referral',
    getHref: () => '/referral',
  },
  // Second page of two-page sign-in flow. See sign-in-flow FF
  welcomeExtraQuestions: {
    path: '/extra',
    getHref: () => '/extra',
  },
  signIn: {
    path: '/sign-in',
    getHref: () => '/sign-in',
  },
  resetPassword: {
    path: '/reset-password',
    getHref: () => '/reset-password',
  },
  forgotPassword: {
    path: '/forgot-password',
    getHref: () => '/forgot-password',
  },
  verifyOtp: {
    path: '/verify-otp',
    getHref: () => '/verify-otp',
  },
  flow: {
    root: {
      path: '/:formResponseReferenceId',
      getHref: (formResponseReferenceId: string) =>
        `/${formResponseReferenceId}`,
    },
    previouslyDiagnosed: {
      root: {
        path: '/:formResponseReferenceId/previously-diagnosed',
        getHref: (formResponseReferenceId: string) =>
          `/${formResponseReferenceId}/previously-diagnosed`,
      },
      start: {
        path: '',
        getHref: (formResponseReferenceId: string) =>
          `/${formResponseReferenceId}/previously-diagnosed`,
      },
      addDiagnoses: {
        path: 'update',
        getHref: (formResponseReferenceId: string) =>
          `/${formResponseReferenceId}/previously-diagnosed/update`,
      },
    },

    geneticHistory: {
      root: {
        path: '/:formResponseReferenceId/genetic-history',
        getHref: (formResponseReferenceId: string) =>
          `/${formResponseReferenceId}/genetic-history`,
      },
      start: {
        path: '',
        getHref: (formResponseReferenceId: string) =>
          `/${formResponseReferenceId}/genetic-history`,
      },
      tests: {
        path: 'tests',
        getHref: (formResponseReferenceId: string) =>
          `/${formResponseReferenceId}/genetic-history/tests`,
      },
      variants: {
        path: 'variants',
        getHref: (formResponseReferenceId: string) =>
          `/${formResponseReferenceId}/genetic-history/variants`,
      },
    },

    labTests: {
      path: '/:formResponseReferenceId/lab-tests',
      getHref: (formResponseReferenceId: string) =>
        `/${formResponseReferenceId}/lab-tests`,
    },

    symptomAssessment: {
      path: 'symptom-assessment',
      getHref: (formResponseReferenceId: string) =>
        `/${formResponseReferenceId}/symptom-assessment`,
    },

    fewQuestions: {
      path: '/:formResponseReferenceId/few-questions',
      getHref: (formResponseReferenceId: string) =>
        `/${formResponseReferenceId}/few-questions`,
    },
  },

  review: {
    root: {
      path: '/:formResponseReferenceId/review',
      getHref: (formResponseReferenceId: string) =>
        `/${formResponseReferenceId}/review`,
    },
    patientInfo: {
      path: '/:formResponseReferenceId/review/patient-info',
      getHref: (formResponseReferenceId: string) =>
        `/${formResponseReferenceId}/review/patient-info`,
    },
    contactInfo: {
      path: '/:formResponseReferenceId/review/contact-info',
      getHref: (formResponseReferenceId: string) =>
        `/${formResponseReferenceId}/review/contact-info`,
    },
    diagnoses: {
      path: '/:formResponseReferenceId/review/diagnoses',
      getHref: (formResponseReferenceId: string) =>
        `/${formResponseReferenceId}/review/diagnoses`,
    },
    labTests: {
      path: '/:formResponseReferenceId/review/lab-tests',
      getHref: (formResponseReferenceId: string) =>
        `/${formResponseReferenceId}/review/lab-tests`,
    },
    patientSymptoms: {
      path: '/:formResponseReferenceId/review/patient-symptoms',
      getHref: (formResponseReferenceId: string) =>
        `/${formResponseReferenceId}/review/patient-symptoms`,
    },
    physicians: {
      path: '/:formResponseReferenceId/review/physicians',
      getHref: (formResponseReferenceId: string) =>
        `/${formResponseReferenceId}/review/physicians`,
    },
  },

  'not-found': {
    path: '/not-found',
    getHref: () => '/not-found',
  },

  dashboard: {
    root: {
      path: '/dashboard',
      getHref: () => '/dashboard',
    },
    status: {
      path: '',
      getHref: () => '/dashboard',
    },
    account: {
      path: 'account',
      getHref: () => '/dashboard/account',
    },
    patient: {
      path: 'patient',
      getHref: () => '/dashboard/patient',
    },
    diagnoses: {
      path: 'diagnoses',
      getHref: () => '/dashboard/diagnoses',
    },
    geneticTests: {
      path: 'genetic-tests',
      getHref: () => '/dashboard/genetic-tests',
    },
    labTests: {
      path: 'lab-tests',
      getHref: () => '/dashboard/lab-tests',
    },
    assessment: {
      path: 'assessment',
      getHref: () => '/dashboard/assessment',
    },
    resources: {
      path: 'resources',
      getHref: () => '/dashboard/resources',
    },
  },
} as const;
