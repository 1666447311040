import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { HeroUIProvider } from '@heroui/react';
import { MainErrorFallback } from '@/components/errors/main';
import { queryConfig } from '@/lib/react-query';
import { Spinner } from '@heroui/react';
import { IS_DEV } from '@/helpers/constants';
import { PHProvider } from '@/app/posthog-provider';
import { createAppRouter } from '@/app/router';
import { RouterProvider } from 'react-router-dom';
import { Suspense, useState } from 'react';

export const AppProvider = () => {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: queryConfig,
      })
  );

  const appRouter = createAppRouter(queryClient);

  return (
    <Suspense
      fallback={
        <div className="flex h-screen w-screen items-center justify-center">
          <Spinner size="lg" />
        </div>
      }
    >
      <HeroUIProvider navigate={appRouter.navigate}>
        <ErrorBoundary FallbackComponent={MainErrorFallback}>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              <PHProvider>
                {IS_DEV && <ReactQueryDevtools />}
                <RouterProvider router={appRouter} />
              </PHProvider>
            </QueryClientProvider>
          </HelmetProvider>
        </ErrorBoundary>
      </HeroUIProvider>
    </Suspense>
  );
};
