import { StatusCardProps } from './StatusCard';
import { NavigateFunction } from 'react-router-dom';
import { ReactNode } from 'react';
import { CardSlots, SlotsToClasses } from '@heroui/theme';

export enum StatusSidebarStatusType {
  Complete = 'complete',
  Waitlisted = 'waitlisted',
  OptedOut = 'opted-out',
  Pending = 'pending',
  PreApproved = 'pre-approved', // intermediate approved status since we style things different before order release
  Approved = 'approved',
  Rejected = 'rejected',
  Loading = 'loading',
}

export enum StatusSidebarStatusTypeInfoNeededMixin {
  InfoNeeded = 'info-needed',
  PreApprovedInfoNeeded = 'pre-approved-info-needed',
  ReadyToSubmit = 'ready-to-submit',
}

export type StatusSidebarStatus =
  | {
      state: StatusSidebarStatusTypeInfoNeededMixin;
      progress: number; // 0 <= number <= 1
    }
  | {
      state: StatusSidebarStatusType;
    };

export enum SidebarItemType {
  Label = 'label',
  Item = 'item',
}

export interface StatusSidebarItemProps {
  key: string;
  type: SidebarItemType;
  active: boolean;
  hidden?: boolean;
  label: string;
  onPress?: () => void;
  status: StatusSidebarStatus;
  renderStatus?: (status: StatusSidebarStatus, active: boolean) => ReactNode;
}

export interface StatusSidebarProps extends Omit<StatusCardProps, 'url'> {
  isLoading?: boolean;
  currentIndex: number;
  navigate: NavigateFunction;
}

export interface ResolveStatusStateProps extends StatusCardProps {
  isLoading?: boolean;
  pageKey: string;
}

export enum FormResponseResult {
  Waitlist = 'Waitlist',
  TrainingResponse = 'Training Response',
  NotInUnitedStates = 'Not In United States',
}

export enum FormResponseStatus {
  InReview = 'In Review',
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  PermanentlyRejected = 'Permanently Rejected',
  OptedOut = 'Opted Out',
  Fraudulent = 'Fraudulent',
  PreEligible = 'Pre Eligible',
}

export interface PageValidation {
  isValid: boolean;
  completeness: Record<string, number>;
  errors: Record<string, any>;
}

export enum OrderStatus {
  PendingPreTestConsult = 'pending-pre-test-consult',
  PendingApproval = 'pending-approval',
  PWNApproved = 'pwn-approved',
  NeedsKit = 'needs-kit',
  ManualLaboratoryRequest = 'manual-laboratory-request',
  KitMailed = 'kit-mailed',
  SamplesReceived = 'samples-received',
  SampleQc = 'sample-qc',
  ExomeQc = 'exome-qc',
  SequencingComplete = 'sequencing-complete',
  AnalysisComplete = 'analysis-complete',
  PendingConsultScheduling = 'pending-consult-scheduling',
  ConsultScheduled = 'consult-scheduled',
  ReportReady = 'report-ready',
}

export enum OrderDisplayBaseStatus {
  InfoNeeded = 'info-needed',
  PreApprovedInfoNeeded = 'pre-approved-info-needed',
  ReadyToSubmit = 'ready-to-submit',
  Waitlist = 'waitlist',
  OptedOut = 'opted-out',
  Rejected = 'rejected',
  InReview = 'in-review',
  Pending = 'pending',
  Approved = 'approved',
  TrainingResponse = 'training-response',
  NotInUnitedStates = 'not-in-united-states',
  PendingPreTestConsult = 'pending-pre-test-consult',
  PendingApproval = 'pending-approval',
}

export enum OrderTimelineDisplayStatus {
  KitPreparing = 'kit-preparing',
  KitMailed = 'kit-mailed',
  SamplesReceived = 'samples-received',
  SequencingComplete = 'sequencing-complete',
  AnalysisComplete = 'analysis-complete',
  PendingConsultScheduling = 'pending-consult-scheduling',
  ConsultScheduled = 'consult-scheduled',
  ReportReady = 'report-ready',
}

export enum OrderVisitStatus {
  Pending = 'visit-pending',
  LabResultsPending = 'visit-lab-results-pending',
  LabResultsComplete = 'visit-lab-results-complete',
}

export enum OrderVisitTimelineDisplayStatus {
  VisitPending = 'visit-pending',
  VisitLabResultsPending = 'visit-lab-results-pending',
  VisitLabResultsComplete = 'visit-lab-results-complete',
}

export type OrderDisplayStatus =
  | OrderDisplayBaseStatus
  | OrderTimelineDisplayStatus
  | OrderVisitTimelineDisplayStatus;

export interface OrderKitSampleTestVariant {
  findingType?: string;
  chromosome?: string;
  startPosition?: number;
  endPosition?: number;
  variantFunction?: string;
  variantType?: string;
  classification?: string;
  geneSymbols?: string;
  cdnaChange?: string[];
  proteinChange?: string;
  transcript?: string;
  inheritance?: string;
  alleleFrequencyTotal?: boolean;
  structuralVariation?: string;
  associatedDisease?: string;
  associatedDiseaseDisplayName?: string;
}

export interface OrderKitSampleTest {
  variants?: OrderKitSampleTestVariant[];
}

export interface OrderKitSample {
  id?: number;
  vcfFileAvailable?: string;
  personReferenceId?: string;
  test?: OrderKitSampleTest[];
}

export interface OrderKit {
  isLost?: boolean;
  isPersonAssigned?: boolean;
  barcode?: string;
  samples?: OrderKitSample[];
}

export interface Order {
  id?: number;
  referenceId?: string;
  status?: OrderStatus | OrderVisitStatus;
  pwnConsultLink?: string;
  pwnConsultDt?: string;
  pwnPreTestConsultDt?: string;
  pwnPreTestConsultLink?: string;
  completedTestingQuestions?: boolean;
  requiresGcPreTestConsult?: boolean;
  isConsentAccepted?: boolean;
  kits?: OrderKit[];
}

export interface DisplayStatusRenderPayload {
  icon?: {
    component: ReactNode;
    title: string;
    className?: string;
  };
  classNames?: SlotsToClasses<CardSlots>;
  getTitle: ReactNode;
  getDescription: (payload: {
    formResponseId: string;
    order?: Order;
    pageValidation?: PageValidation;
    url?: string;
    navigate?: NavigateFunction;
  }) => ReactNode;
  cta?: ReactNode;
}

export interface OrderStepRenderPayload {
  key: OrderTimelineDisplayStatus | OrderVisitTimelineDisplayStatus;
  title: string;
  getDescription: DisplayStatusRenderPayload['getDescription'];
}

export type ReportReadyStatus =
  | OrderStatus.PendingConsultScheduling
  | OrderStatus.ConsultScheduled
  | OrderStatus.ReportReady;
