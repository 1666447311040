import {
  extendVariants,
  Input as NextInput,
  InputProps as _InputProps,
} from '@heroui/react';
import { forwardRef, ForwardRefRenderFunction } from 'react';
import { TestIdMixin } from '../../types';

export interface InputProps extends _InputProps, TestIdMixin {}

// @ts-ignore
const Component: ForwardRefRenderFunction<HTMLInputElement, InputProps> =
  extendVariants(NextInput, {
    variants: {
      size: {
        sm: {
          inputWrapper: [
            'h-[36px] min-h-[36px]',
            'bg-sandstone-200',
            'border-1 border-natural-black-200',
            'data-[hover=true]:bg-sandstone-400',
            'group-data-[focus=true]:bg-white',
            'group-data-[focus=true]:border-natural-black-600',
            'group-data-[invalid=true]:border-danger',
            'group-data-[invalid=true]:!bg-white',
          ],
          input: ['text-md', 'placeholder:text-natural-black-400'],
          label: 'text-sm font-light',
        },
        md: {
          inputWrapper: [
            'h-[44px] min-h-[44px]',
            'bg-sandstone-200',
            'border-1 border-natural-black-200',
            'data-[hover=true]:bg-sandstone-400',
            'group-data-[focus=true]:bg-white',
            'group-data-[focus=true]:border-natural-black-600',
            'group-data-[invalid=true]:border-danger',
            'group-data-[invalid=true]:!bg-white',
          ],
          input: ['text-lg', 'placeholder:text-natural-black-400'],
          label: 'text-sm font-light',
        },
        lg: {
          inputWrapper: [
            'h-[52px] min-h-[52px]',
            'bg-sandstone-200',
            'border-1 border-natural-black-200',
            'data-[hover=true]:bg-sandstone-400',
            'group-data-[focus=true]:bg-white',
            'group-data-[focus=true]:border-natural-black-600',
            'group-data-[invalid=true]:border-danger',
            'group-data-[invalid=true]:!bg-white',
          ],
          input: ['text-xl', 'placeholder:text-natural-black-400'],
          label: 'text-sm font-light',
        },
      },
    },
    defaultVariants: {
      size: 'lg',
      radius: 'sm',
      labelPlacement: 'outside',
    },
  });

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => (
  <Component ref={ref} {...props} />
));
