import {
  cn,
  extendVariants,
  Select as NextSelect,
  SelectItem,
  SelectProps as _SelectProps,
} from '@heroui/react';
import { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react';
import { TestIdMixin } from '../../types';
import { FaCaretDown } from 'react-icons/fa6';

export interface SelectFieldItem {
  key: any;
  value: any;
  label: string | ReactNode;
  description?: string; // to show in a tooltip
}

export interface SelectProps
  extends Omit<_SelectProps, 'children' | 'items' | 'renderValue'>,
    TestIdMixin {
  items?: SelectFieldItem[];
  renderItem?: (item: SelectFieldItem) => ReactNode;
}

// @ts-ignore
const Component: ForwardRefRenderFunction<
  HTMLSelectElement | null,
  _SelectProps
> = extendVariants(NextSelect, {
  variants: {
    size: {
      sm: {
        trigger: [
          'h-[36px] min-h-[36px]',
          'transition-colors',
          'bg-sandstone-200',
          'border-1 border-natural-black-200',
          'data-[hover=true]:bg-sandstone-400',
          'data-[open=true]:bg-white',
          'data-[open=true]:border-natural-black-600',
        ],
        value: ['text-md', 'text-natural-black-400'],
        label: 'text-sm font-light',
      },
      md: {
        trigger: [
          'h-[44px] min-h-[44px]',
          'transition-colors',
          'bg-sandstone-200',
          'border-1 border-natural-black-200',
          'data-[hover=true]:bg-sandstone-400',
          'data-[open=true]:bg-white',
          'data-[open=true]:border-natural-black-600',
        ],
        value: ['text-lg', 'text-natural-black-400'],
        label: 'text-sm font-light',
      },
      lg: {
        trigger: [
          'h-[52px] min-h-[52px]',
          'transition-colors',
          'bg-sandstone-200',
          'border-1 border-natural-black-200',
          'data-[hover=true]:bg-sandstone-400',
          'data-[open=true]:bg-white',
          'data-[open=true]:border-natural-black-600',
        ],
        value: ['text-xl', 'text-natural-black-400'],
        label: 'text-sm font-light',
      },
    },
  },
  defaultVariants: {
    size: 'lg',
    radius: 'sm',
    labelPlacement: 'outside',
    selectorIcon: (<FaCaretDown />) as any,
  },
});

export const Select = forwardRef<HTMLSelectElement | null, SelectProps>(
  ({ items, ...props }, ref) => (
    <Component
      ref={ref}
      {...props}
      classNames={{
        ...props.classNames,
        trigger: cn(
          props.isInvalid && 'border-danger',
          props.classNames?.trigger
        ),
      }}
    >
      <>
        {items?.map((item) => (
          <SelectItem
            key={item.key}
            // @ts-ignore
            value={item.value}
            data-testid={`${props.testId}--${item.key}`}
          >
            {props.renderItem?.(item) || item.label}
          </SelectItem>
        ))}
      </>
    </Component>
  )
);
