import {
  extendVariants,
  Button as NextButton,
  ButtonProps as _ButtonProps,
  InternalForwardRefRenderFunction,
} from '@heroui/react';
import { forwardRef, ForwardRefRenderFunction } from 'react';
import { TestIdMixin } from '../../types';

export interface ButtonProps extends Omit<_ButtonProps, 'size'>, TestIdMixin {
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

// @ts-ignore
const Component: ForwardRefRenderFunction<HTMLButtonElement, ButtonProps> =
  extendVariants(NextButton, {
    variants: {
      size: {
        xs: 'h-8 min-h-8 text-base font-bold py-0.5 px-2 gap-2 items-center',
        sm: 'h-[36px] min-h-[36px] text-sm font-bold',
        md: 'h-[44px] min-h-[44px] text-sm sm:text-md font-bold',
        lg: 'h-[52px] min-h-[52px] text-md sm:text-lg font-bold',
      },
      color: {
        primary: 'bg-primary',
        secondary: 'bg-secondary/60',
      },
      variant: {
        bordered: 'border-2 border-navy bg-transparent text-navy',
      },
    },
    defaultVariants: {
      size: 'lg',
      radius: 'sm',
    },
  });

//@ts-ignore
export const Button: InternalForwardRefRenderFunction<
  'button',
  ButtonProps,
  never
> = forwardRef<HTMLButtonElement | null, ButtonProps>((props, ref) => (
  <Component ref={ref} {...props} />
));
