import {
  cn,
  extendVariants,
  Radio as NextRadio,
  RadioGroup as NextRadioGroup,
  RadioGroupProps as _RadioGroupProps,
  RadioProps,
} from '@heroui/react';
import { TestIdMixin } from '../../types';
import { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react';

export interface RadioGroupProps extends _RadioGroupProps, TestIdMixin {
  items?: RadioProps[];
  info?: ReactNode;
  description?: ReactNode;
}

const defaultGroupSlots = {
  base: 'flex justify-between',
  wrapper: 'flex-nowrap',
  label: 'w-full',
  errorMessage: 'hidden',
  description: 'hidden',
};
const defaultRadioSlots = {
  wrapper:
    'bg-white group-data-[hover-unselected=true]:bg-sandstone-400 group-hover:bg-sandstone-400',
};

// @ts-ignore
const Component: ForwardRefRenderFunction<
  HTMLDivElement | null,
  RadioGroupProps
> = extendVariants(NextRadioGroup, {
  variants: {
    size: {
      sm: defaultGroupSlots,
      md: defaultGroupSlots,
      lg: defaultGroupSlots,
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

// @ts-ignore
const RadioComponent: ForwardRefRenderFunction<
  HTMLDivElement | null,
  RadioProps
> = extendVariants(NextRadio, {
  variants: {
    size: {
      sm: defaultRadioSlots,
      md: defaultRadioSlots,
      lg: defaultRadioSlots,
    },
    color: {
      danger: {
        wrapper: 'border-danger',
      },
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export const RadioGroup = forwardRef<HTMLDivElement | null, RadioGroupProps>(
  (
    { items, description, info, errorMessage, isInvalid, isRequired, ...props },
    ref
  ) => {
    return (
      <div className="flex flex-col">
        <Component
          {...props}
          ref={ref}
          classNames={{
            ...props.classNames,
            base: cn(
              props.orientation === 'horizontal'
                ? 'flex-row gap-4'
                : 'flex-col',
              props.classNames?.base
            ),
            wrapper: cn(
              props.orientation === 'horizontal' ? 'gap-4' : 'gap-2',
              props.orientation === 'horizontal' && info && 'items-start mt-2',
              props.classNames?.wrapper
            ),
          }}
          label={
            <div className="flex flex-col justify-center w-full">
              <div className={cn('min-h-10 py-2 flex flex-col justify-center')}>
                <label
                  className={cn(
                    isRequired &&
                      "after:content-['*'] after:text-danger after:ml-0.5 rtl:after:ml-[unset] rtl:after:mr-0.5 after:font-normal"
                  )}
                >
                  {props.label}
                </label>
                {description && !(isInvalid && errorMessage) && (
                  <div className="text-natural-black-600 text-tiny">
                    {description}
                  </div>
                )}
                {isInvalid && errorMessage && (
                  <div className="text-danger text-tiny">
                    {String(errorMessage)}
                  </div>
                )}
              </div>
              {info && (
                <div className="bg-sea-green-200 py-2 px-3 rounded-lg text-navy-1000">
                  {info}
                </div>
              )}
            </div>
          }
        >
          {items?.map((item) => (
            <RadioComponent
              key={item.value}
              {...item}
              color={isInvalid ? 'danger' : 'primary'}
            />
          ))}
        </Component>
      </div>
    );
  }
);
