import { ClassNameMixin, ErrorsMixin, TestIdMixin } from '../../../types';
import { v4 as uuidv4 } from 'uuid';
import pick from 'lodash.pick';

export enum Pathogenicity {
  Pathogenic = 'pathogenic',
  LikelyPathogenic = 'likely-pathogenic',
  Vus = 'vus',
  LikelyBenign = 'likely-benigh', // Existing typo in FindingPathogenicities
  Benign = 'benigh', // Existing typo in FindingPathogenicities
  Unknown = 'unknown',
}

export const PATHOGENICITIES_OPTIONS = [
  {
    key: Pathogenicity.Pathogenic,
    value: Pathogenicity.Pathogenic,
    label: 'Pathogenic',
  },
  {
    key: Pathogenicity.LikelyPathogenic,
    value: Pathogenicity.LikelyPathogenic,
    label: 'Likely Pathogenic',
  },
  {
    key: Pathogenicity.Vus,
    value: Pathogenicity.Vus,
    label: 'VUS',
  },
  {
    key: Pathogenicity.LikelyBenign,
    value: Pathogenicity.LikelyBenign,
    label: 'Likely Benign',
  },
  {
    key: Pathogenicity.Benign,
    value: Pathogenicity.Benign,
    label: 'Benign',
  },
  {
    key: Pathogenicity.Unknown,
    value: Pathogenicity.Unknown,
    label: "I don't know",
  },
];

export enum TestResult {
  Positive = 'positive',
  Negative = 'negative',
  Uncertain = 'uncertain',
}

export interface Variant {
  id: number | string;
  geneSymbol: string | null;
  pathogenicity: Pathogenicity | null;
  cdnaChange: string | null;
}

export interface GeneticTestingHistoryRowProps {
  id: number | string;
  testType: GeneticLabTest | null;
  relative: Relative | null;
  testingYear: number | null;
  variants: Variant[];
  result: TestResult | null;
}

export interface GeneticTestingHistoryInputFieldsProp
  extends Pick<
    GeneticTestingHistoryTestsProps,
    'id' | 'testId' | 'errors' | 'onChangeRow' | 'onAddRow' | 'onRemoveRow'
  > {
  row: GeneticTestingHistoryRowProps;
  rowIndex: number;
  action?: 'add' | 'remove';
}

export interface GeneticTestingHistoryTestsProps
  extends TestIdMixin,
    ClassNameMixin,
    ErrorsMixin {
  id?: string;
  rows: GeneticTestingHistoryRowProps[];
  onAddRow: (row: GeneticTestingHistoryRowProps) => void;
  onRemoveRow: (row: GeneticTestingHistoryRowProps) => void;
  onChangeRow: (row: GeneticTestingHistoryRowProps) => void;
}

export interface GeneticTestingHistoryVariantsProps
  extends TestIdMixin,
    ClassNameMixin,
    ErrorsMixin {
  id?: string;
  rows: GeneticTestingHistoryRowProps[];
  onChangeStatus: (row: GeneticTestingHistoryRowProps) => void;
  onRemoveRow: GeneticTestingHistoryTestsProps['onRemoveRow'];
  onAddVariant: (variant: Variant, row: GeneticTestingHistoryRowProps) => void;
  onRemoveVariant: (
    variant: Variant,
    row: GeneticTestingHistoryRowProps
  ) => void;
  onChangeVariant: (
    variant: Variant,
    row: GeneticTestingHistoryRowProps
  ) => void;
  apiUrl: string;
}

export function createNewRow(): GeneticTestingHistoryRowProps {
  return {
    id: `new-${uuidv4()}`,
    testType: null,
    relative: null,
    testingYear: null,
    variants: [createNewVariant()],
    result: null,
  };
}

export const EMPTY_INPUT_ROW = pick(createNewRow(), [
  'testType',
  'relative',
  'testingYear',
]);

export function createNewVariant(): Variant {
  return {
    id: `new-variant-${uuidv4()}`,
    geneSymbol: null,
    pathogenicity: null,
    cdnaChange: null,
  };
}

export enum Relative {
  'Patient' = 'patient',
  'Father' = 'father',
  'Mother' = 'mother',
  'Sister' = 'sister',
  'Brother' = 'brother',
  'Son' = 'son',
  'Daughter' = 'daughter',
  'Aunt' = 'aunt',
  'Uncle' = 'uncle',
  'Niece' = 'niece',
  'Nephew' = 'nephew',
  'Half-sibling' = 'half-sibling',
  'Grandfather' = 'grandfather',
  'Grandmother' = 'grandmother',
  'Cousin' = 'cousin',
  'Other' = 'other',
}

export const RELATIVE_OPTIONS = Object.entries(Relative).map(
  ([key, value]) => ({
    key: value,
    value,
    label: key,
  })
);

// @ts-ignore
export const RELATIVE_TO_LABEL: Record<Relative, string> = Object.fromEntries(
  Object.entries(Relative).map(([key, value]) => [
    value,
    key === 'Patient' ? 'Patient' : `Patient's ${key}`,
  ])
);

export enum GeneticLabTest {
  CHROMOSOMAL_MICROARRAY_ANALYSIS = 'chromosomal-microarray-analysis',
  FISH_TEST = 'fish-test',
  GENE_PANEL = 'gene-panel',
  KARYOTYPE_GENETIC_TEST = 'karyotype-genetic-test',
  METHYLATION_TEST = 'methylation-test',
  RNA_SEQUENCING = 'rna-sequencing',
  WHOLE_EXOME_SEQUENCING = 'whole-exome-sequencing',
  WHOLE_GENOME_SEQUENCING = 'whole-genome-sequencing',
  OTHER_GENETIC_TEST = 'other-genetic-test',
}

export enum OtherLabTest {
  BRAIN_MRI = 'brain-mri',
  BRAIN_PET = 'brain-pet',
  EEG = 'eeg',
  LUMBAR_PUNCTURE = 'lumbar-puncture',
  SLEEP_STUDY = 'sleep-study',
}

export const OTHER_LAB_TESTS_OPTIONS = [
  {
    key: OtherLabTest.BRAIN_MRI,
    value: OtherLabTest.BRAIN_MRI,
    label: 'Brain MRI',
  },
  {
    key: OtherLabTest.BRAIN_PET,
    value: OtherLabTest.BRAIN_PET,
    label: 'Brain PET',
  },
  {
    key: OtherLabTest.EEG,
    value: OtherLabTest.EEG,
    label: 'EEG',
  },
  {
    key: OtherLabTest.LUMBAR_PUNCTURE,
    value: OtherLabTest.LUMBAR_PUNCTURE,
    label: 'Lumbar Puncture',
  },
  {
    key: OtherLabTest.SLEEP_STUDY,
    value: OtherLabTest.SLEEP_STUDY,
    label: 'Sleep Study',
  },
];

export const GENETIC_LAB_TEST_OPTIONS = [
  {
    key: GeneticLabTest.WHOLE_EXOME_SEQUENCING,
    value: GeneticLabTest.WHOLE_EXOME_SEQUENCING,
    label: 'Whole Exome Sequencing',
  },
  {
    key: GeneticLabTest.WHOLE_GENOME_SEQUENCING,
    value: GeneticLabTest.WHOLE_GENOME_SEQUENCING,
    label: 'Whole Genome Sequencing',
  },
  {
    key: GeneticLabTest.GENE_PANEL,
    value: GeneticLabTest.GENE_PANEL,
    label: 'Gene Panel',
  },
  {
    key: GeneticLabTest.CHROMOSOMAL_MICROARRAY_ANALYSIS,
    value: GeneticLabTest.CHROMOSOMAL_MICROARRAY_ANALYSIS,
    label: 'Chromosomal Microarray',
  },
  {
    key: GeneticLabTest.FISH_TEST,
    value: GeneticLabTest.FISH_TEST,
    label: 'Fish Test',
  },
  {
    key: GeneticLabTest.KARYOTYPE_GENETIC_TEST,
    value: GeneticLabTest.KARYOTYPE_GENETIC_TEST,
    label: 'Karyotype Genetic Test',
  },
  {
    key: GeneticLabTest.METHYLATION_TEST,
    value: GeneticLabTest.METHYLATION_TEST,
    label: 'Methylation Test',
  },
  {
    key: GeneticLabTest.RNA_SEQUENCING,
    value: GeneticLabTest.RNA_SEQUENCING,
    label: 'RNA Sequencing',
  },
  {
    key: GeneticLabTest.OTHER_GENETIC_TEST,
    value: GeneticLabTest.OTHER_GENETIC_TEST,
    label: 'Genetic test (type unknown)',
  },
];

// @ts-ignore
export const LAB_TEST_TO_LABEL: Record<GeneticLabTest, string> =
  Object.fromEntries(
    GENETIC_LAB_TEST_OPTIONS.map(({ key, label }) => [key, label])
  );
