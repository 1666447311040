import { QueryClient } from '@tanstack/react-query';
import { createBrowserRouter } from 'react-router-dom';
import { paths } from '@pg-web/bearded-dragon-ui';
import { ErrorFallback } from '@/components/errors/ErrorFallback';

// For more examples how we should
// https://github.com/alan2207/bulletproof-react/blob/master/apps/react-vite/src/app/router.tsx

export const createAppRouter = (queryClient: QueryClient) =>
  // https://reactrouter.com/6.28.0/routers/create-browser-router
  createBrowserRouter(
    [
      // Example route
      // {
      //   path: paths.example.path,
      //   lazy: async () => {
      //     const { ExampleRoute } = await import('./routes/example');
      //
      //     // If you need to load data before the component loads, add in a
      //     // `loader` prop here. This `createAppRouter` function gets passed
      //     // in a `queryClient` so you can use that directly.
      //     // Example usage:
      //     //  * https://github.com/alan2207/bulletproof-react/blob/master/apps/react-vite/src/app/router.tsx#L50
      //     //  * https://github.com/alan2207/bulletproof-react/blob/master/apps/react-vite/src/app/routes/app/discussions/discussions.tsx#L10
      //     return {
      //       Component: ExampleRoute,
      //       // loader: exampleLoader
      //     };
      //   },
      // },

      {
        path: paths.root.path,
        lazy: async () => {
          const { RootLayout: Component } = await import(
            '../components/layouts/RootLayout'
          );
          return { Component };
        },
        children: [
          {
            path: paths.welcome.path,
            lazy: async () => {
              const { IntakeRoute } = await import('./routes/intake');
              return { Component: IntakeRoute };
            },
          },
          {
            path: paths.welcomeWithReferral.path,
            lazy: async () => {
              const { IntakeWithReferralRoute } = await import(
                './routes/intake-with-referral'
              );
              return { Component: IntakeWithReferralRoute };
            },
          },
          {
            path: paths.welcomeExtraQuestions.path,
            lazy: async () => {
              const { IntakeExtraQuestionsRoute } = await import(
                './routes/intake-extra-questions'
              );
              return { Component: IntakeExtraQuestionsRoute };
            },
          },
          {
            path: paths.signIn.path,
            lazy: async () => {
              const { SignInRoute: Component } = await import(
                './routes/sign-in'
              );
              return { Component };
            },
          },
          {
            path: paths.resetPassword.path,
            lazy: async () => {
              const { ResetPasswordRoute: Component } = await import(
                './routes/reset-password'
              );
              return { Component };
            },
          },
          {
            path: paths.forgotPassword.path,
            lazy: async () => {
              const { ForgotPasswordRoute: Component } = await import(
                './routes/forgot-password'
              );
              return { Component };
            },
          },
          {
            path: paths.flow.root.path,
            lazy: async () => {
              const { FlowLayout: Component } = await import(
                '../components/layouts/FlowLayout'
              );
              return { Component };
            },
            ErrorBoundary: ErrorFallback,
            children: [
              {
                path: paths.flow.previouslyDiagnosed.root.path,
                lazy: async () => {
                  const { PreviouslyDiagnosed: Component } = await import(
                    './routes/previously-diagnosed'
                  );
                  return { Component };
                },
                children: [
                  {
                    path: paths.flow.previouslyDiagnosed.start.path,
                    lazy: async () => {
                      const { PreviouslyDiagnosedIntakeForm: Component } =
                        await import(
                          '../features/previously-diagnosed/components/PreviouslyDiagnosedIntakeForm'
                        );
                      return { Component };
                    },
                  },
                  {
                    path: paths.flow.previouslyDiagnosed.addDiagnoses.path,
                    lazy: async () => {
                      const { PreviouslyDiagnosedUpdateForm: Component } =
                        await import(
                          '../features/previously-diagnosed/components/PreviouslyDiagnosedUpdateForm'
                        );
                      return { Component };
                    },
                  },
                ],
              },

              {
                path: paths.flow.geneticHistory.root.path,
                lazy: async () => {
                  const { GeneticHistoryRoute: Component } = await import(
                    './routes/genetic-history'
                  );
                  return { Component };
                },
                children: [
                  {
                    path: paths.flow.geneticHistory.start.path,
                    lazy: async () => {
                      const { GeneticHistoryIntakeFeature: Component } =
                        await import(
                          '../features/genetic-history/components/GeneticHistoryIntakeFeature'
                        );
                      return { Component };
                    },
                  },
                  {
                    path: paths.flow.geneticHistory.tests.path,
                    lazy: async () => {
                      const { GeneticHistoryTestsFeature: Component } =
                        await import(
                          '../features/genetic-history/components/GeneticHistoryTestsFeature'
                        );
                      return { Component };
                    },
                  },
                  {
                    path: paths.flow.geneticHistory.variants.path,
                    lazy: async () => {
                      const { GeneticHistoryVariantsFeature: Component } =
                        await import(
                          '../features/genetic-history/components/GeneticHistoryVariantsFeature'
                        );
                      return { Component };
                    },
                  },
                ],
              },
              {
                path: paths.flow.labTests.path,
                lazy: async () => {
                  const { LabTestsRoute } = await import('./routes/lab-tests');
                  return { Component: LabTestsRoute };
                },
              },

              {
                path: paths.flow.symptomAssessment.path,
                lazy: async () => {
                  const { SymptomAssessmentRoute } = await import(
                    './routes/symptom-assessment'
                  );
                  return { Component: SymptomAssessmentRoute };
                },
              },

              {
                path: paths.flow.fewQuestions.path,
                lazy: async () => {
                  const { FewQuestionsRoute: Component } = await import(
                    './routes/few-questions'
                  );
                  return { Component };
                },
              },
            ],
          },
          {
            path: paths.dashboard.root.path,
            lazy: async () => {
              const { DashboardLayout: Component } = await import(
                '../components/layouts/DashboardLayout'
              );
              return { Component };
            },
            ErrorBoundary: ErrorFallback,
            children: [
              {
                path: paths.dashboard.status.path,
                lazy: async () => {
                  const { StatusRoute } = await import(
                    './routes/dashboard/status'
                  );
                  return { Component: StatusRoute };
                },
              },
              {
                path: paths.dashboard.account.path,
                lazy: async () => {
                  const { AccountRoute } = await import(
                    './routes/dashboard/account'
                  );
                  return { Component: AccountRoute };
                },
              },
              {
                path: paths.dashboard.patient.path,
                lazy: async () => {
                  const { PatientRoute } = await import(
                    './routes/dashboard/patient'
                  );
                  return { Component: PatientRoute };
                },
              },
              {
                path: paths.dashboard.diagnoses.path,
                lazy: async () => {
                  const { DiagnosesRoute } = await import(
                    './routes/dashboard/diagnoses'
                  );
                  return { Component: DiagnosesRoute };
                },
              },
              {
                path: paths.dashboard.geneticTests.path,
                lazy: async () => {
                  const { GeneticTestsRoute } = await import(
                    './routes/dashboard/genetic-tests'
                  );
                  return { Component: GeneticTestsRoute };
                },
              },
              {
                path: paths.dashboard.labTests.path,
                lazy: async () => {
                  const { LabTestsRoute } = await import(
                    './routes/dashboard/lab-tests'
                  );
                  return { Component: LabTestsRoute };
                },
              },
              {
                path: paths.dashboard.assessment.path,
                lazy: async () => {
                  const { AssessmentRoute } = await import(
                    './routes/dashboard/assessment'
                  );
                  return { Component: AssessmentRoute };
                },
              },
              {
                path: paths.dashboard.resources.path,
                lazy: async () => {
                  const { ResourcesRoute } = await import(
                    './routes/dashboard/resources'
                  );
                  return { Component: ResourcesRoute };
                },
              },
            ],
          },

          // The review route doesn't have the top navigation container, so it
          // gets its own route here.
          {
            path: paths.review.root.path,
            lazy: async () => {
              const { ReviewRoot: Component } = await import(
                './routes/review/review-root'
              );
              return {
                Component: Component,
              };
            },
            children: [
              {
                path: paths.review.root.path,
                lazy: async () => {
                  const { ReviewRoute: Component } = await import(
                    './routes/review/review'
                  );
                  return {
                    Component: Component,
                  };
                },
              },
              {
                path: paths.review.patientInfo.path,
                lazy: async () => {
                  const { PatientInfoRoute: Component } = await import(
                    './routes/review/patient-info'
                  );
                  return {
                    Component: Component,
                  };
                },
              },
              {
                path: paths.review.contactInfo.path,
                lazy: async () => {
                  const { ContactInfoRoute: Component } = await import(
                    './routes/review/contact-info'
                  );
                  return {
                    Component: Component,
                  };
                },
              },
              {
                path: paths.review.diagnoses.path,
                lazy: async () => {
                  const { DiagnosesRoute: Component } = await import(
                    './routes/review/diagnoses'
                  );
                  return {
                    Component: Component,
                  };
                },
              },
              {
                path: paths.review.labTests.path,
                lazy: async () => {
                  const { LabTestsReviewRoute: Component } = await import(
                    './routes/review/lab-tests'
                  );
                  return {
                    Component: Component,
                  };
                },
              },
              {
                path: paths.review.patientSymptoms.path,
                lazy: async () => {
                  const { PatientSymptomsRoute: Component } = await import(
                    './routes/review/patient-symptoms'
                  );
                  return {
                    Component: Component,
                  };
                },
              },
              {
                path: paths.review.physicians.path,
                lazy: async () => {
                  const { PhysiciansReviewRoute } = await import(
                    './routes/review/physicians'
                  );
                  return { Component: PhysiciansReviewRoute };
                },
              },
            ],
          },

          {
            path: 'not-found',
            lazy: async () => {
              const { NotFoundRoute } = await import('./routes/not-found');
              return {
                Component: NotFoundRoute,
              };
            },
            ErrorBoundary: ErrorFallback,
          },

          // This should be at the bottom
          {
            path: '*',
            lazy: async () => {
              const { NotFoundRoute } = await import('./routes/not-found');
              return {
                Component: NotFoundRoute,
              };
            },
            ErrorBoundary: ErrorFallback,
          },
        ],
      },
    ],
    {
      basename: import.meta.env.BASE_URL || '/', // Use BASE_URL from Vite
    }
  );
