import {
  extendVariants,
  Autocomplete as NextAutocomplete,
  AutocompleteItem,
  AutocompleteProps as _AutocompleteProps,
} from '@heroui/react';
import { forwardRef, ForwardRefRenderFunction, ReactNode } from 'react';
import { TestIdMixin } from '../../types';
import { FaMagnifyingGlass } from 'react-icons/fa6';

export interface AutocompleteFieldItem {
  key: any;
  value: any;
  label: string | ReactNode;
}

export interface AutocompleteProps
  extends Omit<_AutocompleteProps, 'children' | 'items'>,
    TestIdMixin {
  items?: AutocompleteFieldItem[];
  renderItem?: (item: AutocompleteFieldItem) => ReactNode;
}

// @ts-ignore
const Component: ForwardRefRenderFunction<
  HTMLInputElement,
  _AutocompleteProps
> = extendVariants(NextAutocomplete, {
  defaultVariants: {
    size: 'lg',
    radius: 'sm',
    labelPlacement: 'outside',
    selectorIcon: (<FaMagnifyingGlass />) as any,
    disableSelectorIconRotation: 'true',
  },
});

export const Autocomplete = forwardRef<HTMLInputElement, AutocompleteProps>(
  (props, ref) => (
    <Component
      ref={ref}
      {...props}
      inputProps={{
        classNames: {
          ...(props.inputProps?.classNames || {}),
          inputWrapper: [
            'transition-colors',
            'bg-sandstone-200',
            'border-1 border-natural-black-200',
            'data-[hover=true]:bg-sandstone-400',
            'data-[open=true]:bg-white',
            'data-[open=true]:border-natural-black-600',
            'data-[focus=true]:border-natural-black-600',
            'group-data-[invalid=true]:border-danger',
            {
              xs: 'h-8 min-h-8',
              sm: 'h-[36px] min-h-[36px]',
              md: 'h-[44px] min-h-[44px]',
              lg: 'h-[52px] min-h-[52px]',
            }?.[props.size ?? 'lg'],
            props.inputProps?.classNames?.inputWrapper,
          ],
          input: [
            'placeholder:text-natural-black-400',
            {
              xs: 'text-base',
              sm: 'text-md',
              md: 'text-lg',
              lg: 'text-xl',
            }?.[props.size ?? 'lg'],
            props.inputProps?.classNames?.input,
          ],
          label: ['text-sm font-light', props.inputProps?.classNames?.label],
        },
      }}
    >
      <>
        {(item: any) => (
          <AutocompleteItem
            key={item.key}
            // @ts-ignore
            value={item.value}
            data-testid={`${props.testId}--${item.key}`}
          >
            {props.renderItem?.(item as AutocompleteFieldItem) || item.label}
          </AutocompleteItem>
        )}
      </>
    </Component>
  )
);
