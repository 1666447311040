import { gql, GraphQLClient } from 'graphql-request';
import * as Sentry from '@sentry/browser';
import { getCookie } from '../../../../../utils';

interface DownloadSampleDataDTO {
  formResponseId: string;
  sampleId: string;
}

interface DownloadReportDataDTO {
  formResponseId: string;
}

async function gqlMutate<T>(
  operationName: string,
  query: string,
  variables?: Record<string, any>,
  url: string = `https://patient-replica.probablygenetic.com/api/graphql/`
): Promise<T> {
  const csrftoken = getCookie('csrftoken');
  let response: T;
  try {
    const client = new GraphQLClient(url, {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'X-CSRFToken': csrftoken,
      },
    });
    response = await client.request(
      gql`
        ${query}
      `,
      variables ?? {}
    );
  } catch (e: any) {
    // Standardize error catching with REST
    Sentry.captureException(e, {
      level: 'error',
      extra: { status: 400, statusText: e.message, query },
    });
    throw { status: 400, statusText: e.message, query };
  }
  return (response as Record<string, any>)[operationName];
}

export async function downloadReport(
  variables: {
    input: DownloadReportDataDTO;
  },
  url?: string
): Promise<string> {
  return (
    await gqlMutate<{ url: string }>(
      'downloadReport',
      `mutation downloadReport($input: DownloadReportInput) {
        downloadReport(input: $input) {
          url
        }
      }`,
      variables,
      url
    )
  ).url;
}

export async function downloadData(
  variables: {
    input: DownloadSampleDataDTO;
  },
  url?: string
): Promise<string> {
  return (
    await gqlMutate<{ url: string }>(
      'downloadData',
      `mutation downloadData($input: DownloadDataInput) {
        downloadData(input: $input) {
          __typename
          url
        }
      }`,
      variables,
      url
    )
  ).url;
}
