import {
  extendVariants,
  Link as NextLink,
  LinkProps as _LinkProps,
} from '@heroui/react';
import { TestIdMixin } from '../../types';
import { forwardRef, ForwardRefRenderFunction } from 'react';

export interface LinkProps extends _LinkProps, TestIdMixin {}

// @ts-ignore
const Component: ForwardRefRenderFunction<HTMLAnchorElement, LinkProps> =
  extendVariants(NextLink, {
    defaultVariants: {
      underline: 'always',
    },
  });

export const Link = forwardRef<HTMLAnchorElement | null, LinkProps>(
  (props, ref) => <Component ref={ref} {...props} />
);
