import React, { PropsWithChildren } from 'react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { uuidv7 } from 'posthog-js/lib/src/uuidv7';

const POSTHOG_API_KEY = import.meta.env.VITE_POSTHOG_PROJECT_API_KEY;
const POSTHOG_PROXY_URL = import.meta.env.VITE_POSTHOG_PROXY_URL;

if (typeof window !== 'undefined' && POSTHOG_API_KEY) {
  const queryParams = new URLSearchParams(window.location.search);
  const distinctId =
    Object.fromEntries(queryParams.entries())?.phId || uuidv7();
  posthog.init(POSTHOG_API_KEY, {
    api_host: POSTHOG_PROXY_URL,
    ui_host: 'https://us.posthog.com',
    person_profiles: 'always',
    autocapture: {
      element_allowlist: [],
      css_selector_allowlist: ['[ph-autocapture]'],
    },
    disable_surveys: true,
    bootstrap: {
      distinctID: distinctId,
    },
    capture_pageview: false,
    session_recording: {
      maskTextSelector: '.sensitive',
    },
  });
}

export const PHProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
};
