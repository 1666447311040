import {
  cn,
  Skeleton as NextSkeleton,
  SkeletonProps as _SkeletonProps,
} from '@heroui/react';
import { forwardRef } from 'react';
import { TestIdMixin } from '../../types';

export interface SkeletonProps extends _SkeletonProps, TestIdMixin {}

export const Skeleton = forwardRef<HTMLElement | null, SkeletonProps>(
  (props, ref) => (
    <NextSkeleton
      ref={ref}
      {...props}
      classNames={{
        ...(props.classNames ?? {}),
        base: cn(
          'bg-sandstone-200 before:via-content3 rounded-lg',
          props.classNames?.base
        ),
      }}
    />
  )
);
