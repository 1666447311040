import {
  extendVariants,
  Card,
  CardProps,
  CardBody,
  useDisclosure,
  cn,
} from '@heroui/react';
import { TestIdMixin } from '../../types';
import { forwardRef, ForwardRefRenderFunction } from 'react';
import { IoMdClose } from 'react-icons/io';
import { Button } from './Button';
import { FaCircleXmark } from 'react-icons/fa6';

export interface BannerProps extends CardProps, TestIdMixin {
  color?: 'default' | 'primary' | 'success' | 'danger' | 'gray' | 'info';
  isCloseable?: boolean;
  persistKey?: string; // persist close state to local storage. If undefined, don't persist
}

// @ts-ignore
const Component: ForwardRefRenderFunction<HTMLDivElement, BannerProps> =
  extendVariants(Card, {
    variants: {
      color: {
        default: {
          base: 'bg-sandstone-400',
          body: 'p-4 font-bold',
        },
        gray: {
          base: 'bg-natural-black/5',
          body: 'p-4 font-bold',
        },
        primary: {
          base: 'bg-primary',
          body: 'p-4 text-white font-bold',
        },
        success: {
          base: 'bg-robins-egg-blue-200',
          body: 'p-4 text-black font-bold',
        },
        danger: {
          base: 'bg-peach-200',
          body: 'p-4 text-danger font-bold',
        },
        info: {
          base: 'bg-robins-egg-blue-200',
          body: 'p-4 text-natural-black',
        },
      },
    },
    defaultVariants: {
      color: 'default',
      radius: 'sm',
      shadow: 'none',
    },
  });

export const Banner = forwardRef<HTMLDivElement | null, BannerProps>(
  ({ children, isCloseable = false, persistKey, ...props }, ref) => {
    const { isOpen, onClose } = useDisclosure({
      defaultOpen: (() => {
        if (!persistKey) {
          return true;
        }
        const storedValue = localStorage.getItem(persistKey);
        return storedValue !== null ? JSON.parse(storedValue) : true;
      })(),
      onChange: (nextOpen) => {
        if (!persistKey) {
          return;
        }
        localStorage.setItem(persistKey, String(nextOpen));
      },
    });

    return isOpen ? (
      <Component ref={ref} {...props}>
        <CardBody className={cn(isCloseable && 'pr-8', 'flex flex-row gap-2')}>
          {props.color === 'danger' && (
            <FaCircleXmark size={18} className="mt-0.5" />
          )}
          <div>{children}</div>
        </CardBody>
        {isCloseable && (
          <Button
            className="absolute top-0.5 right-0.5"
            size="sm"
            radius="full"
            isIconOnly
            variant="light"
            onPress={onClose}
          >
            <IoMdClose className="text-natural-black-600 text-2xl" />
          </Button>
        )}
      </Component>
    ) : (
      <></>
    );
  }
);
